<template>
  <div class="container pdu-section">
    <div class="pdu-title">
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>
    </div>
    <div class="row m-0">
      <!-- Left Column -->
      <div class="col-lg-4">
        <div
            v-for="(item, index) in parsedLeftColumnItems"
            :key="'left-' + index"
            class="pdu-item justify-content-start  justify-content-lg-end"
        >
          <div class="pdu-text">
            <h5>{{ item.title }}</h5>
            <p>{{ item.text }}</p>
          </div>
          <img :src="item.image" :alt="item.title"/>
        </div>
      </div>
      <!-- Center Image -->
      <div class="col-lg-4 pdu-image">
        <img :src="centerImage" alt="PDU Image" />
      </div>
      <!-- Right Column -->
      <div class="col-lg-4">
        <div
          v-for="(item, index) in parsedRightColumnItems"
          :key="'right-' + index"
          class="pdu-item"
        >
          <img :src="item.image" :alt="item.title" />
          <div class="pdu-text pdu_text_2">
            <h5>{{ item.title }}</h5>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductDetailTwo",
  props: {
    title: {
      type: String,
      default: "Built for Reliability",
    },
    description: {
      type: String,
      default:
        "The PDU features hot-swappable monitoring, allowing repairs or upgrades without turning off power, ensuring IT devices stay online longer. Patented locking outlets securely hold cords in place, preventing accidental unplugging and ensuring a stable connection.",
    },
    leftColumnitems: {
      type: String,
      default: JSON.stringify([
        {
          title: "Clear LCD Display",
          text: "The 2.8” color LCD with high resolution shows detailed and clear PDU data and settings.",
          image: "Image/Display-4h-1%201.png",
        },
      ]),
    },
    centerImage: {
      type: String,
      default: "Image/Rectangle%201037.png",
    },
    rightColumnitems: {
      type: String,
      default: JSON.stringify([
        {
          title: "Clear LCD Display",
          text: "The 2.8” color LCD with high resolution shows detailed and clear PDU data and settings.",
          image: "Image/Display-4h-1%201.png",
        },
      ]),
    },
  },
  computed: {
    parsedLeftColumnItems() {
      try {
        return JSON.parse(this.leftColumnitems);
      } catch (error) {
        console.error("Invalid JSON for leftColumnitems:", error);
        return [];
      }
    },
    parsedRightColumnItems() {
      try {
        return JSON.parse(this.rightColumnitems);
      } catch (error) {
        console.error("Invalid JSON for rightColumnitems:", error);
        return [];
      }
    },
  },
};
</script>


<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.pdu-section {
  padding: 2rem 0;
}

.pdu-title {
  text-align: center;
  margin-bottom: 2rem;
}

.pdu-title h2 {
  color: #000;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}

.pdu-title p {
  color: #696969;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.pdu-item {
  display: flex;
  align-items: start;
  margin-bottom: 2rem;
  gap: 20px;
}

.pdu-item img {
  width: 50px;
  height: 50px;
}

.pdu-text {
  margin-left: 1rem;
}

.pdu-text h5 {
  color: #1E1E1E;
  text-align: right;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}

.pdu-text p {
  color: #696969;
  text-align: right;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.pdu_text_2 h5 {
  text-align: start !important;
}

.pdu_text_2 p {
  text-align: start !important;
}

.pdu-image {
  text-align: center;
}

.pdu-image img {
  max-width: 100%;
  height: auto;
}


@media only screen and (max-width: 375px) {
  .pdu-title h2 {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.5px;
    margin-bottom: 16px;
  }

  .pdu-title p {
    text-align: center;
    font-size: 12px;
    line-height: 10px;
  }

  .pdu-text h5 {
    font-size: 16px;

  }

  .pdu-text p {
    color: #696969;
    text-align: right;
    font-size: 14px;
  }

  .pdu-section {
    padding:0 20px ;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .pdu-title h2 {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.5px;
    margin-bottom: 16px;
  }

  .pdu-title p {
    text-align: center;
    font-size: 12px;
    line-height: 10px;
  }

  .pdu-text h5 {
    font-size: 16px;

  }

  .pdu-text p {
    color: #696969;
    text-align: right;
    font-size: 14px;
  }

  .pdu-section {
    padding:0 20px ;
  }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .pdu-title h2 {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.5px;
    margin-bottom: 16px;
  }

  .pdu-title p {
    text-align: center;
    font-size: 12px;
    line-height: 10px;
  }

  .pdu-text h5 {
    font-size: 16px;

  }

  .pdu-text p {
    color: #696969;
    text-align: right;
    font-size: 14px;
  }

  .pdu-section {
    padding:0 20px ;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .pdu-section {
    padding:0 40px ;
  }
}

@media only screen and  (min-width: 992px) and (max-width: 1199px) {

}
</style>