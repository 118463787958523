<template>
  <nav aria-label="breadcrumb" class="d-none d-lg-block">
    <ol class="breadcrumb">
      <li v-for="(item, index) in breadcrumbs" :key="index" class="breadcrumb-item"
          :class="{ 'active': item.active }">
        <template v-if="index === 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
            <path
                d="M1 5.8125L8.875 1L16.75 5.8125M15 8.875V14.475C15 14.765 14.765 15 14.475 15H3.275C2.98505 15 2.75 14.765 2.75 14.475V8.875"
                stroke="#0F1E2A" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
        <a v-if="!item.active" :href="item.link">{{ item.text }}</a>
        <span v-else>{{ item.text }}</span>
      </li>
    </ol>
  </nav>
  <div class="download-section container-xxl">
    <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center">
      <div class="mb-4 text-center text-lg-start">
        <h2>Download</h2>
        <p>Click on any product to download the file.</p>
      </div>

      <div class="filters d-flex flex-column flex-md-row gap-3 gap-md-5">
        <select v-model="selectedCategory">
          <option value="">Product Categories</option>
          <option v-for="category in categoriesData" :key="category.id" :value="category">
            {{ category.title }}
          </option>
        </select>
        <select v-model="selectedSubCategory">
          <option value="">Sub Categories</option>
          <option
              v-for="subCategory in availableSubCategories"
              :key="subCategory.id"
              :value="subCategory"
          >
            {{ subCategory.name }}
          </option>
        </select>
        <button class="bTn" @click="search">Search</button>
      </div>
    </div>

    <div class="product-grid">
      <div
          class="product-card"
          v-for="(product, index) in filteredProducts"
          :key="index"
      >
        <div class="product-image">
          <img :src="product.image" :alt="product.name"/>
          <div class="download-icon" @click="openPopup(product)">
            <img src="./Download_image/download.png"/>
          </div>
        </div>
        <p class="product-name">{{ product.title }}</p>
      </div>
    </div>

    <!-- Popup Modal -->
    <div v-if="showPopup" class="popup-overlay" @click.self="closePopup">
      <div class="popup-content">
        <div v-if="selectedProduct && documents.length > 0">
          <h5>{{ selectedProduct.title }}</h5>
          <down-load
            id="download"
            :first-title="documents[0]?.document_name"
            :second-title="documents[1]?.document_name"
            :first-url="documents[0]?.document_file_url"
            :second-url="documents[1]?.document_file_url"
          ></down-load>
        </div>
        <div v-else>
          <p>No documents are available for this product.</p>
        </div>
        <button class="bTn" @click="closePopup">Close</button>
      </div>
    </div>


  </div>
</template>

<script>
import axios from "axios";
import Download from './DownLoadTwo.vue';
export default {
  props: ['products', 'categories'],
  components: {
    'down-load': Download,
  },
  data() {
    return {
      selectedCategory: "",
      selectedSubCategory: "",
      categoriesData: JSON.parse(this.categories),
      productsData: JSON.parse(this.products),
      showPopup: false,
      selectedProduct: {},
      documents: [],
    };
  },
  computed: {
    // Get subcategories for the selected category
    availableSubCategories() {
      const category = this.selectedCategory;
      return category ? category.subcategories : [];
    },
    // Filter products by selected category and subcategory
    filteredProducts() {
      return this.productsData.filter(
          (product) =>
              (!this.selectedCategory || product.subcategory.category.id === this.selectedCategory.id) &&
              (!this.selectedSubCategory || product.subcategory.id === this.selectedSubCategory.id)
      );
    },
  },
  watch: {
    // Automatically select the first subcategory when a category is selected
    selectedCategory(newCategory) {
      if (newCategory && newCategory.subcategories.length > 0) {
        this.selectedSubCategory = newCategory.subcategories[0];
      } else {
        this.selectedSubCategory = "";
      }
    },
  },
  methods: {
    search() {
      console.log("Search triggered!");
    },
    async openPopup(product) {
      this.selectedProduct = product;
      console.log(this.selectedProduct);
      try {
        const response = await axios.get(`/document/${product.id}/`);

        // Check if the response has documents or a success message
        if (response.data.success === "No Documents Found.") {
          this.documents = []; // Clear documents if none are found

        } else {
          this.documents = response.data.documents; // Store the list of documents
        }

        this.showPopup = true; // Open the popup regardless to display a message or documents
      } catch (error) {
        console.error("Error fetching documents:", error);
        alert("Failed to fetch the documents. Please try again.");
      }
    },
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.breadcrumb {
  background: #F6F6F6;
  padding: 20px 80px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '';
  background-image: url("./Product_Details_Image/Vector (3).svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  width: 12px;
  height: 12px;
}

.download_area h4 {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-transform: capitalize;
}

.download_area a {
  color: #0081B4;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 100% */
  font-variant: all-small-caps;
}

.download-section {
  text-align: center;
   margin-bottom: 80px;
}

.filters {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(309px, 1fr));
  gap: 20px;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s;
}

.product-card:hover {
  transform: scale(1.05);
  background: rgba(11, 26, 46, 0.30);
}

.product-image {
  position: relative;
  height: 200px;
}

.product-image img {
  width: 100%;
  height: 100%;
}

.download-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  display: none;
}

.product-card:hover .download-icon {
  display: block;
}

.product-name {
  margin-top: 10px;
  font-size: 16px;
}

.filters select, button {
  height: fit-content;
}

.bTn {
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: 0.5px;
  border-radius: 8px;
  border: 1px solid #E2E2E2;
  background: #222;
  padding: 10px 20px;
}

select {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  letter-spacing: 0.5px;
  padding: 10px 18px;
  border-radius: 8px 8px;
  border: 1px solid #E2E2E2;
  background: #FFF;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: fit-content;
}

.popup-content h3 {
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 100% */
}

.popup-content a {
  display: block;
  color: #007bff;
  text-decoration: none;
  margin: 10px 0;
}

.popup-content a:hover {
  text-decoration: underline;
}

.close-btn {
  padding: 10px 20px;
  background: transparent;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


@media only screen and (max-width: 375px) {
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 20px;
  }

  .filters {
    width: 100%;
  }

  select, .bTn {
    width: auto;
  }

  .download-section {
    text-align: center;
    margin: 20px;
  }

  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    width: 100%;
    padding: 20px;
    margin-right: 0;
    margin-left: 0;
  }

}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 20px;
  }

  .filters {
    width: 100%;
  }

  .download-section {
    text-align: center;
    margin: 20px;
  }

  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    width: 100%;
    padding: 20px;
    margin-right: 0;
    margin-left: 0;
  }

}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
    gap: 20px;
  }

  .filters {
    width: 100%;
  }

  .download-section {
    text-align: center;
    margin: 20px;
  }

  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    width: 100%;
    padding: 20px;
    margin-right: 0;
    margin-left: 0;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(229px, 1fr));
    gap: 20px;
  }

  .download-section {
    text-align: center;
    margin: 40px;
  }

  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    width: 100%;
    padding: 20px;
    margin-right: 0;
    margin-left: 0;
  }

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
    gap: 20px;
  }

  .download-section {
    text-align: center;
  }

  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    width: 100%;
    padding: 40px;
    margin-right: 0;
    margin-left: 0;
  }

}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1440px) {

}
</style>
