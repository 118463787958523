<template>
  <div class="server_area">
    <div class="server_header d-block d-lg-none pt-4">
      <h2>Simplify Server Management</h2>
      <p>Streamlining Server Control</p>
    </div>
    <div class="server">
      <!-- Carousel 1 -->
      <div id="carouselExampleCaptions1" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner h-100">
          <div class="carousel-item active">
            <img :src="carouselItems[0].image" class="d-block w-100" alt="Server image">
            <div class="carousel-caption">
              <h2 class="first_text">{{ carouselItems[0].title }}</h2>
              <a :href="carouselItems[0].link" class="link_btn" @click.prevent="navigateToUrl(carouselItems[0].link)">{{ carouselItems[0].buttonText }}</a>
            </div>
          </div>
        </div>
      </div>

      <!-- Carousel 2 -->
      <div id="carouselExampleCaptions2" class="carousel slide d-none d-lg-block" data-bs-ride="carousel">
        <div class="carousel-inner h-100">
          <div class="carousel-item h-100 active">
            <div class="carousel-caption mid-carousel">
              <h2 class="mid_text">{{ carouselItems[1].title }}</h2>
              <span class="mid_span">{{ carouselItems[1].description }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Carousel 3 -->
      <div id="carouselExampleCaptions3" class="carousel slide d-none d-lg-block" data-bs-ride="carousel">
        <div class="carousel-inner h-100">
          <div class="carousel-item active">
            <img :src="carouselItems[2].image" class="d-block w-100" alt="Server image">
            <div class="carousel-caption">
              <h2 class="last_text">{{ carouselItems[2].title }}</h2>
              <a :href="carouselItems[2].link" class="link_btn" @click.prevent="navigateToUrl(carouselItems[2].link)">{{ carouselItems[2].buttonText }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {ref} from 'vue'

export default {
  name: 'ServerCarousel',
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const carouselItems = ref([
      {
        image: 'https://safenet.bitquintet.com/media/images/Link_1.png',
        title: 'Widescreen LCD TP KVM over IP',
        link: props.url,
        buttonText: 'Get it Now'
      },
      {
        title: 'Simplify Server Management',
        description: 'Streaming Server Control'
      },
      {
        image: 'https://safenet.bitquintet.com/media/images/Link_1.png',
        title: 'Widescreen LCD TP KVM over IP',
        link: props.url,
        buttonText: 'Get it Now'
      }
    ])

    return {
      carouselItems
    }
  },
  methods: {
    navigateToUrl(url) {
      // Navigate to the given URL
      window.location.href = url;
    },

  },
  created() {

  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
.server {
  display: flex;
  gap: 30px;
  padding: 80px;
  justify-content: center;
}

.carousel {
  width: 400px;
}

.carousel-caption {
  top: 20%;
  position: absolute;
  right: 5%;
  bottom: 1.25rem;
  left: 5%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.link_btn {
  color: #FFF;
  text-align: right;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
}

.first_text, .last_text {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 171.429% */
}

.mid_text {
  color: #1E1E1E;
  text-align: center;
  font-family: ;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  letter-spacing: 0.8px;
  text-transform: capitalize;
}
.mid_span {
  color: #222;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
}

.carousel-caption a {
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 40px;
  border: 2px solid #FFF;
  display: inline-block;
}

.mid_text {
  color: black !important;
}

.mid_link {
  border: 2px solid #000000 !important;
  color: black !important;
  width: fit-content;
  display: flex;
  justify-content: center;
}

.mid-carousel {
  position: static;
  color: #000;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#carouselExampleCaptions2 .carousel-inner {
  background-color: #fff;
}

@media only screen and (max-width: 375px) {
  .server {
    padding: 20px;
  }

  .server_area {
    padding: 20px;
  }

  .server_header h2 {
    color: #1E1E1E;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: 0.8px;
    text-transform: capitalize;
  }

  .server_header p {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
  }

  .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
  }

  .carousel-caption a {
    color: #FFF;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    display: inline-block;
    padding: 13px 34px;
  }

}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .server {
    padding: 20px;
  }

  .server_area {
    padding: 20px;
  }

  .server_header h2 {
    color: #1E1E1E;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: 0.8px;
    text-transform: capitalize;
  }

  .server_header p {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
  }

  .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
  }

  .carousel-caption a {
    color: #FFF;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    display: inline-block;
    padding: 13px 34px;
  }

}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .server {
    padding: 20px;
  }

  .server_area {
    padding: 20px;
  }

  .server_header h2 {
    color: #1E1E1E;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: 0.8px;
    text-transform: capitalize;
  }

  .server_header p {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
  }

  .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
  }

  .carousel-caption a {
    color: #FFF;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    display: inline-block;
    padding: 13px 34px;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .server {
    padding: 20px;
  }

  .server_area {
    padding: 20px;
  }

  .server_header h2 {
    color: #1E1E1E;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: 0.8px;
    text-transform: capitalize;
  }

  .server_header p {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
  }

  .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
  }

  .carousel-caption a {
    color: #FFF;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    display: inline-block;
    padding: 13px 34px;
  }

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .carousel-caption{
    top: 70px;
    padding: 0;
  }

.first_text, .last_text {
  font-size: 22px;
  line-height: 40px;
}

.mid_text {
  font-size: 30px;
  line-height: 45px;
}

}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1440px) {

}

</style>