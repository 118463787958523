<template>
  <div class="Artiv">
    <header>
      <nav class="navbar navbar-expand-lg Artive-nav-hero">
        <div class="container-fluid w-100">
          <a class="navbar-brand" href="/">
            <img src="./Navbar_img/Picture1%202.png" alt=""/>
          </a>
          <div class="d-flex align-items-center">
            <div class="d-flex gap-3">
              <div class="nav-item search_area d-block d-lg-none mobile_search">
                <div class="nav-link">
                  <div class="dropdown-center">
                    <button type="button" class="btn dropdown-toggle nav-search" data-bs-toggle="dropdown"
                            data-bs-display="static" aria-expanded="false" @click="toggleSearch">
                      <svg width="18" height="18" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M21.6836 21L16.8503 16.1667M19.4614 9.88889C19.4614 14.7981 15.4817 18.7778 10.5725 18.7778C5.66328 18.7778 1.68359 14.7981 1.68359 9.88889C1.68359 4.97969 5.66328 1 10.5725 1C15.4817 1 19.4614 4.97969 19.4614 9.88889Z"
                              stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <ul class="dropdown-menu" style="width: max-content; max-width: 18.75rem;">
                      <li>
                        <div class="d-flex align-items-center">
                          <div class="p-2 d-flex align-items-center w-100">
                            <div class="search-icon" v-if="searchActive">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                   fill="none">
                                <path
                                    d="M13 13L10.1 10.1M11.6667 6.33333C11.6667 9.27885 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27885 1 6.33333C1 3.38781 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38781 11.6667 6.33333Z"
                                    stroke="#1E1E1E" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                            <input class="search-box" type="text" v-model="searchString"
                                   :class="{ active: searchActive }"
                                   ref="searchBox" placeholder="search here.." @focus="searchActive = true" style="width: 113%;"/>
                            <div class="close-search" v-if="searchActive" @click="closeSearch">
                              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12"
                                   fill="none">
                                <path
                                    d="M7.16148 6.00041L10.6965 2.46441C10.792 2.37216 10.8682 2.26181 10.9206 2.13981C10.973 2.01781 11.0006 1.88659 11.0017 1.75381C11.0029 1.62103 10.9776 1.48935 10.9273 1.36645C10.877 1.24356 10.8028 1.1319 10.7089 1.03801C10.615 0.944118 10.5033 0.869865 10.3804 0.819584C10.2575 0.769303 10.1259 0.744001 9.99307 0.745155C9.8603 0.746309 9.72908 0.773895 9.60707 0.826304C9.48507 0.878713 9.37472 0.954895 9.28248 1.05041L5.74648 4.58541L2.21148 1.05041C2.11923 0.954895 2.00888 0.878713 1.88688 0.826304C1.76488 0.773895 1.63366 0.746309 1.50088 0.745155C1.3681 0.744001 1.23642 0.769303 1.11352 0.819584C0.990625 0.869865 0.878973 0.944118 0.785081 1.03801C0.691188 1.1319 0.616935 1.24356 0.566654 1.36645C0.516373 1.48935 0.491071 1.62103 0.492225 1.75381C0.493379 1.88659 0.520965 2.01781 0.573374 2.13981C0.625783 2.26181 0.701966 2.37216 0.797476 2.46441L4.33248 5.99941L0.797476 9.53541C0.701966 9.62765 0.625783 9.738 0.573374 9.86C0.520965 9.98201 0.493379 10.1132 0.492225 10.246C0.491071 10.3788 0.516373 10.5105 0.566654 10.6334C0.616935 10.7563 0.691188 10.8679 0.785081 10.9618C0.878973 11.0557 0.990625 11.1299 1.11352 11.1802C1.23642 11.2305 1.3681 11.2558 1.50088 11.2547C1.63366 11.2535 1.76488 11.2259 1.88688 11.1735C2.00888 11.1211 2.11923 11.0449 2.21148 10.9494L5.74648 7.41441L9.28248 10.9494C9.37472 11.0449 9.48507 11.1211 9.60707 11.1735C9.72908 11.2259 9.8603 11.2535 9.99307 11.2547C10.1259 11.2558 10.2575 11.2305 10.3804 11.1802C10.5033 11.1299 10.615 11.0557 10.7089 10.9618C10.8028 10.8679 10.877 10.7563 10.9273 10.6334C10.9776 10.5105 11.0029 10.3788 11.0017 10.246C11.0006 10.1132 10.973 9.98201 10.9206 9.86C10.8682 9.738 10.792 9.62765 10.6965 9.53541L7.16148 6.00041Z"
                                    fill="#848E99"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </li>
                      <div class="suggestion-div " v-if="!searchString">
                        <ul class="list-group">
                          <li class="list-group-item mt-2" v-for="result in results" :key="result.id"
                              @mousedown.prevent="selectResult(result)" @click.stop="keepSearchActive">+ {{
                              result.label
                            }}
                          </li>
                        </ul>
                      </div>
                      <div class="show-products d-flex align-items-center overflow-x-hidden overflow-y-scroll"
                           v-if="searchString && fetchedProducts.length > 0" style="height: 31.25rem;">
                        <ul class="list-group">
                          <li class="list-group-item mt-2 border-bottom cursor-pointer" v-for="product in fetchedProducts"
                              :key="product.id"
                              @click="selectResult(product)">
                            <a :href="`/product/${product.slug}`">
                              <img :src="product.image" alt="no image found" class="search-images">
                              <div class="text-black" style="font-size: 1rem;">{{ product.title }}</div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div v-if="searchString && fetchedProducts.length === 0">
                        No product found
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <button class="navbar-toggler text-white" type="button" data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"
                      aria-label="Toggle navigation">
                          <span class="">
                              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12"
                                   fill="none">
                                  <path d="M0.792969 6.08368H18.2103M0.792969 1H18.2103M0.792969 11H18.2103" stroke="white" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </span>
              </button>
            </div>
            <button class="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"
                  aria-label="Toggle navigation">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12" fill="none">
                <path d="M0.792969 6.08368H18.2103M0.792969 1H18.2103M0.792969 11H18.2103"
                      stroke="black" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
          </button>
          </div>

          <div class="offcanvas offcanvas-end " data-bs-scroll="true" tabindex="-1" id="offcanvasNavbar"
               aria-labelledby="offcanvasWithBothOptionsLabel">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel"></h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="offcanvas-body align-items-center justify-content-between d-none d-lg-flex">
              <ul class="navbar-nav d-flex justify-content-between align-items-center mx-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link" aria-current="page" href="/">Home</a>
                </li>
                <li
                    class="nav-item dropdown"
                    v-on:mouseover="toggleMainDropdown('productsOpen', true)"
                    v-on:mouseleave="toggleMainDropdown('productsOpen', false)">
                  <a class="nav-link dropdown-toggle" href="/all-product" role="button" aria-expanded="false">
                    Product
                  </a>
                  <div v-if="productsOpen" class="dropdown-menu mega-menu p-4" aria-labelledby="productDropdown">
                    <mega-menu :categoriesData="categoriesData" :productsData="productsData"/>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-nowrap" href="/about-us">About Us</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/contact-us">Contact</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/download-center">Download</a>
                </li>
                <li class="nav-item search_area">
                  <div class="nav-link">
                    <div class="dropdown-center">
                      <button type="button" style="background: transparent; border: none" class="  nav-search"
                              data-bs-toggle="dropdown"
                              data-bs-display="static" aria-expanded="false" @click="toggleSearch">
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M21.6836 21L16.8503 16.1667M19.4614 9.88889C19.4614 14.7981 15.4817 18.7778 10.5725 18.7778C5.66328 18.7778 1.68359 14.7981 1.68359 9.88889C1.68359 4.97969 5.66328 1 10.5725 1C15.4817 1 19.4614 4.97969 19.4614 9.88889Z"
                              stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <div class="d-flex align-items-center">
                            <div class="p-2 d-flex align-items-center w-100">
                              <div class="search-icon" v-if="searchActive">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                     fill="none">
                                  <path
                                      d="M13 13L10.1 10.1M11.6667 6.33333C11.6667 9.27885 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27885 1 6.33333C1 3.38781 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38781 11.6667 6.33333Z"
                                      stroke="#1E1E1E" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </div>
                              <input class="search-box" type="text" v-model="searchString"
                                     :class="{ active: searchActive }"
                                     ref="searchBox" placeholder="search here.." @focus="searchActive = true"
                                     style="width: 113%; min-width: 300px;"/>
                              <div class="close-search" v-if="searchActive" @click="closeSearch">
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12"
                                     fill="none">
                                  <path
                                      d="M7.16148 6.00041L10.6965 2.46441C10.792 2.37216 10.8682 2.26181 10.9206 2.13981C10.973 2.01781 11.0006 1.88659 11.0017 1.75381C11.0029 1.62103 10.9776 1.48935 10.9273 1.36645C10.877 1.24356 10.8028 1.1319 10.7089 1.03801C10.615 0.944118 10.5033 0.869865 10.3804 0.819584C10.2575 0.769303 10.1259 0.744001 9.99307 0.745155C9.8603 0.746309 9.72908 0.773895 9.60707 0.826304C9.48507 0.878713 9.37472 0.954895 9.28248 1.05041L5.74648 4.58541L2.21148 1.05041C2.11923 0.954895 2.00888 0.878713 1.88688 0.826304C1.76488 0.773895 1.63366 0.746309 1.50088 0.745155C1.3681 0.744001 1.23642 0.769303 1.11352 0.819584C0.990625 0.869865 0.878973 0.944118 0.785081 1.03801C0.691188 1.1319 0.616935 1.24356 0.566654 1.36645C0.516373 1.48935 0.491071 1.62103 0.492225 1.75381C0.493379 1.88659 0.520965 2.01781 0.573374 2.13981C0.625783 2.26181 0.701966 2.37216 0.797476 2.46441L4.33248 5.99941L0.797476 9.53541C0.701966 9.62765 0.625783 9.738 0.573374 9.86C0.520965 9.98201 0.493379 10.1132 0.492225 10.246C0.491071 10.3788 0.516373 10.5105 0.566654 10.6334C0.616935 10.7563 0.691188 10.8679 0.785081 10.9618C0.878973 11.0557 0.990625 11.1299 1.11352 11.1802C1.23642 11.2305 1.3681 11.2558 1.50088 11.2547C1.63366 11.2535 1.76488 11.2259 1.88688 11.1735C2.00888 11.1211 2.11923 11.0449 2.21148 10.9494L5.74648 7.41441L9.28248 10.9494C9.37472 11.0449 9.48507 11.1211 9.60707 11.1735C9.72908 11.2259 9.8603 11.2535 9.99307 11.2547C10.1259 11.2558 10.2575 11.2305 10.3804 11.1802C10.5033 11.1299 10.615 11.0557 10.7089 10.9618C10.8028 10.8679 10.877 10.7563 10.9273 10.6334C10.9776 10.5105 11.0029 10.3788 11.0017 10.246C11.0006 10.1132 10.973 9.98201 10.9206 9.86C10.8682 9.738 10.792 9.62765 10.6965 9.53541L7.16148 6.00041Z"
                                      fill="#848E99"/>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </li>
                        <div class="suggestion-div " v-if="!searchString">
                          <ul class="list-group">
                            <li class="list-group-item mt-2" v-for="result in results" :key="result.id"
                                @mousedown.prevent="selectResult(result)" @click.stop="keepSearchActive">+ {{
                                result.label
                              }}
                            </li>
                          </ul>
                        </div>
                        <div class="show-products d-flex align-items-center overflow-x-hidden overflow-y-scroll"
                             v-if="searchString && fetchedProducts.length > 0" style="height: 31.25rem;">
                          <ul class="list-group">
                            <li class="list-group-item mt-2 border-bottom cursor-pointer"
                                v-for="product in fetchedProducts"
                                :key="product.id"
                                @click="selectResult(product)">
                              <a :href="`/product/${product.slug}`" class="search-product-link d-flex gap-3">
                                <img :src="product.image" alt="no image found" class="search-images">
                                <div class="text-black" style="font-size: 1rem;">{{ product.title }}</div>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div v-if="searchString && fetchedProducts.length === 0">
                          No product found
                        </div>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>

            </div>

            <ul class="navbar-mobile d-block d-lg-none overflow-scroll">
              <li class="home main-nav">
                <div class="ps-2"><a href="/" class="text-decoration-none" style="color: #1E1E1E;">Home</a></div>
              </li>
              <li class="products main-nav" @click="toggleMainDropdown('productsOpen')">
                <div class="px-2 pb-2 d-flex justify-content-between">
                  <div>Products</div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                      <path d="M11 1.5L6 6.5L1 1.5" stroke="#444444" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
                <transition name="fade-slide">
                  <ul v-if="productsOpen" class="category-list overflow-y-scroll">
                    <!-- Category List -->
                    <li v-for="category in categoriesData" :key="category.id" class="category"
                        @click.stop="toggleCategory(category.id)">
                      <div class="px-2 pb-2 d-flex justify-content-between">
                        <div>{{ category.title }}</div>
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                            <path d="M11 1.5L6 6.5L1 1.5" stroke="#444444" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                          </svg>
                        </div>
                      </div>

                      <!-- Subcategory List -->
                      <transition name="fade-slide">
                        <ul v-if="categoryOpen[category.id]" class="subcategory-list">
                          <li v-for="subcategory in category.subcategories" :key="subcategory.id" class="subcategory"
                              @click.prevent="navigateToCategory(category.id, subcategory.id)">
                            <div class="px-3 d-flex justify-content-between">
                              <div>{{ subcategory.name }}</div>
                              <div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </transition>
                    </li>

                    <!-- Show All Products Link -->
                    <li><a @click="showAllProducts(categoriesData[0].title)" style="color: #1E1E1E">All Products</a>
                    </li>
                  </ul>
                </transition>


              </li>

              <li class="about-us main-nav">
                <div class="ps-2"><a class="text-decoration-none" href="/about-us/" style="color: #1E1E1E">About Us</a>
                </div>
              </li>
              <li class="contact main-nav">
                <div class="ps-2"><a class="text-decoration-none" href="/contact-us/" style="color: #1E1E1E">Contact
                </a></div>
              </li>
              <li class="contact main-nav">
                <div class="ps-2"><a class="text-decoration-none" href="/download-center/" style="color: #1E1E1E">Download
                </a></div>
              </li>
            </ul>

          </div>




        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  props: ['products', 'categories'],
  data() {
    return {
      searchQuery: '',
      items: ['Interactive Flat panel', 'Digital Signage', 'Mini Projector'],
      productsOpen: true, // Controls the Products dropdown
      solutionsOpen: false, // Controls the Solutions dropdown
      smartOpen: false, // Controls the Smart Education dropdown under Solutions
      categoryOpen: {}, // Controls the state of each category's dropdown
      subcategoryOpen: {},
      searchString: "",
      categoriesData: JSON.parse(this.categories),
      productsData: JSON.parse(this.products),
      fetchedProducts: [],
      results: [
        {id: '1', label: 'Intelligent Modular PDU', image: ''},
        {id: '2', label: 'KVM Console', image: ''},
        {id: '3', label: 'Perforated Data Center Rack', image: ''},
      ],
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(item =>
          item.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  mounted() {
    this.printData();
  },
  watch: {
    searchString(newValue) {
      console.log('Search String:', newValue); // Logs the updated search string
      this.filterProducts(); // Filters products based on the new search string
    },
  },


  methods: {
    printData() {
      console.log(this.categoriesData);
      console.log(this.productsData);
    },
    navigateToAllProducts() {
      window.location.href = `/all-product`;
    },
    navigateToCategory(categoryId, subcategoryId) {
      window.location.href = `/all-product?category=${categoryId}&subcategory=${subcategoryId}`;
    },
    toggleMainDropdown(dropdown, isHover = null) {
      if (isHover !== null) {
        this[dropdown] = isHover;
        return;
      }
      // Toggle the main dropdowns (Products, Solutions, Smart)
      if (dropdown === 'productsOpen') {
        this.productsOpen = !this.productsOpen;
        this.solutionsOpen = false;
        this.smartOpen = false;
      } else if (dropdown === 'solutionsOpen') {
        this.solutionsOpen = !this.solutionsOpen;
        this.productsOpen = false;
        this.smartOpen = false;
      } else if (dropdown === 'smartOpen') {
        this.smartOpen = !this.smartOpen;
      }
    },
    toggleCategory(categoryId) {
      // Check if categoryOpen state is initialized, and toggle it
      if (!(categoryId in this.categoryOpen)) {
        this.categoryOpen[categoryId] = false;
      }
      this.categoryOpen[categoryId] = !this.categoryOpen[categoryId];
    },
    toggleSubcategory(subcategoryId) {
      // Check if subcategoryOpen state is initialized, and toggle it
      if (!(subcategoryId in this.subcategoryOpen)) {
        this.subcategoryOpen[subcategoryId] = false;
      }
      this.subcategoryOpen[subcategoryId] = !this.subcategoryOpen[subcategoryId];
    },
    filteredProducts(subcategoryId) {
      // Filter products based on the selected subcategory ID
      return this.productsData.filter(product => product.subcategory.id === subcategoryId);
    },
    showAllProducts(categoryTitle) {
      // Navigate or perform action to show all products under a specific category
      console.log(`Showing all products for category: ${categoryTitle}`);
      window.location.href = `/category/${categoryTitle.replace(/\s+/g, '-').toLowerCase()}`;
    },
    navigateToSolution(solutionName) {
      // Navigate to the solution page
      window.location.href = `/solution/${solutionName.replace(/\s+/g, '-').toLowerCase()}`;
    },
    keepSearchActive() {
      this.searchActive = true;
    },
    toggleSearch() {
      this.searchActive = !this.searchActive;

      if (this.searchActive) {
        this.$nextTick(() => {
          this.$refs.searchBox.focus();
        });
      } else {
        this.searchString = '';
      }
    },
    selectResult(result) {
      this.searchString = result.label;
      console.log(this.searchString);
      this.filterProducts();
      this.searchActive = true;
    },
    filterProducts() {
      if (!this.searchString) {
        this.fetchedProducts = [];
        return;
      }
      const searchLower = this.searchString.toLowerCase();

      this.fetchedProducts = this.productsData.filter(product =>
          product.title.toLowerCase().includes(searchLower) ||
          product.subcategory?.name?.toLowerCase().includes(searchLower) ||
          product.subcategory?.category?.title?.toLowerCase().includes(searchLower)
      );
    },
    closeSearch() {
      this.searchActive = false;
      this.searchString = '';
      this.suggestionsActive = false;
    },
    clearSearch() {
      this.searchQuery = '';
    },
  },
};
</script>


<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
.mega-menu .swiper-wrapper{
  overflow: scroll;
}
.mobile_search.search_area .dropdown-menu{
  top: 3rem !important;
  left: -13rem !important;
}
.mobile_search.search_area .dropdown-menu .list-group-item {
  border: none;
  cursor: pointer;
}
.mobile_search.search_area .dropdown-menu .list-group-item a{
  padding: 0 !important;
}
.mobile_search.search_area .dropdown-menu .list-group-item a img {
  max-width: 13.75rem;
}

/* search input start */
.search-product-link {
  padding: 0 !important;
}
.search-images {
  max-width: 5rem;
  max-height: 5rem;
  margin-bottom: 1rem;
}
.btn.show {
  border-color: transparent;
}

.mobile_search.search_area .dropdown-menu {
  top: 3rem !important;
  left: -13rem !important;
}

.mobile_search.search_area .dropdown-menu .list-group-item {
  border: none;
  cursor: pointer;
}

.mobile_search.search_area .dropdown-menu .list-group-item a {
  padding: 0 !important;
}

.mobile_search.search_area .dropdown-menu .list-group-item a img {
  max-width: 13.75rem;
}

.search-box {
  padding: 0.75rem 1.25rem 0.75rem 2.25rem;
  border: 1px solid transparent;
  background: #e5e5e5;
  border-radius: 5px;
}

.search-box:focus {
  outline: 0;
}

.search-icon {
  margin-right: -2rem;
  z-index: 1001;
}

.close-search {
  margin-left: -2rem;
}

.search_area .dropdown-menu {
  position: absolute !important;
  top: 3.8rem !important;
  left: -20rem !important;
  padding: 1rem 2rem;
  transform: none !important; /* Prevents Bootstrap's auto positioning */
  will-change: transform; /* Helps maintain the dropdown position */
}

/*search input end*/
.dropdown-menu.mega-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-45%);
  top: 100%; /* Position just below the dropdown */
  width: 100vw; /* Full width of the screen */
  background: white;
  padding: 20px;
  border: none;
  border-radius: 8px;
  z-index: 1050; /* Ensure it's above other elements */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex !important;
  flex-wrap: wrap; /* Allow wrapping of child elements if necessary */
  justify-content: center; /* Center the contents */
  gap: 20px; /* Add spacing between items */
}

.card {
  flex: 1 0 300px; /* Allow the cards to shrink and wrap on smaller screens */
  max-width: 380px; /* Keep a maximum width */
  margin: 0 auto; /* Center align each card */
}

.category-cards-container {
  display: flex;
  flex-wrap: wrap; /* Wrap items to avoid horizontal scrolling */
  justify-content: center; /* Center the cards */
  gap: 20px; /* Add space between cards */
}

.search-container {
  width: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  background: white;
}

.input-wrapper {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 8px;
  padding-right: 24px; /* Space for the clear button */
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
}

.clear-button {
  position: absolute;
  top: 40%;
  right: 8px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 16px;
  color: #888;
  cursor: pointer;
}

.clear-button:hover {
  color: #555;
}

.item-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px 0;
  cursor: pointer;
}

.item span {
  margin-right: 8px;
  color: #888;
}
.search_box{
  right: 0% !important;
  left: 80% !important;
  background: transparent;
  border: none;
}
/* Styles from the original HTML */
.dropdown-menu.mega-menu {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-item.dropdown:hover .dropdown-menu.mega-menu {
  display: block;
  opacity: 1;
  visibility: visible;
}
.offcanvas-header {
  display: flex;
  justify-content: end;
  padding-right: 1rem;
}

.offcanvas-header .btn-close {
  margin: 0;
  padding: 1.5rem;
}
.navbar-mobile .main-nav {
  padding: 1rem 1.563rem;
  list-style-type: none;
  border-bottom: 1px solid #DBDFD0;
  cursor: pointer;
}

.navbar-mobile li {
  list-style-type: none;
}

.category-list, .solution-list {
  background: #f2f2f2;
  cursor: pointer;
}

.category-list li {
  padding: 1rem 2rem;
  list-style-type: none;
}

.solution-list li {
  padding: 1rem 2rem;
}

.smart-education-list li {
  padding: 1rem 2.5rem;
  list-style-type: none;
}

.mobile_search.search_area .dropdown-menu{
  top: 3rem !important;
  left: -13rem !important;
}
.mobile_search.search_area .dropdown-menu .list-group-item {
  border: none;
  cursor: pointer;
}
.mobile_search.search_area .dropdown-menu .list-group-item a{
  padding: 0 !important;
}
.mobile_search.search_area .dropdown-menu .list-group-item a img {
  max-width: 13.75rem;
}






* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.Artive-nav-hero {
  padding: 30px 80px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
}

.navbar-nav {
  gap: 50px;
}

.navbar-nav li {
  position: relative;
  background: transparent;
  border: none;
  outline: none;
}

.navbar-nav li a::before {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 0.175rem;
  background: #1ae5fe;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.navbar-nav li a:hover::before {
  transform: scale(1, 1);
}

.navbar-nav a {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
  padding: 10px 20px;
}

.navbar-toggler {
  background: transparent;
  box-shadow: none !important;
  border: none !important;
}

.dropdown-toggle::after {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: -5px;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  transform: rotate(-50deg);
}


.search_area {
  display: flex;
}

@media only screen and (max-width: 375px) {


}

@media only screen and (min-width: 376px) and (max-width: 425px) {


}

@media only screen and (min-width: 426px) and (max-width: 767px) {

}

@media only screen and  (min-width: 768px) and (max-width: 991px) {


}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1366px) {

}

@media only screen and  (min-width: 1201px) and (max-width: 1440px) {
  .dropdown-menu.mega-menu {
    left: 50%;
    transform: translateX(-43%);
    top: 100%;
  }
}


@media only screen and (max-width: 991px) {
  .Artive-nav-hero {
    padding: 15px 20px;
  }

  .navbar-nav {
    flex-direction: column;
    gap: 20px;
  }

  .nav-item a {
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 30px 20px !important;
  }

  .dropdown-toggle::after {
    top: 20px;
    right: 0;
  }

  .search_area {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .Artive-nav-hero {
    padding: 30px 20px;
  }

  .dropdown-toggle::after {
    top: 20px;
    right: -4px;
  }

  .navbar-nav {
    gap: 30px;
  }
}
</style>
