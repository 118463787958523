<template>
  <div class="swiper-container">
    <swiper
        :slidesPerView="4"
        :spaceBetween="50"
        :pagination="{ clickable: true }"
        :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
        :breakpoints="{
        320: { slidesPerView: 1, spaceBetween: 50 },
        426: { slidesPerView: 2, spaceBetween: 50 },
        992: { slidesPerView: 3, spaceBetween: 50 },
        1500: { slidesPerView: 4, spaceBetween: 50 },
      }"
        :modules="modules"
        class="mySwiper"
    >
      <swiper-slide v-for="product in limitedProducts" :key="product.id">
        <div class="card position-relative">
          <span class="badge bg-success" v-if="product.status">{{ product.status }}</span>
          <img :src="product.image" class="card-img-top" :alt="product.title">
          <div class="card-body">
            <p class="card-text">{{ product.title }}</p>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: "ProductShowcase",
  props: ['products', 'category'],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      productsData: JSON.parse(this.products),
      categoryData: this.category,
      modules: [Navigation, Pagination],
    };
  },
  computed: {
    filteredProducts() {
      return this.productsData.filter(product => {
        console.log(product); // Inspect product structure
        return product.subcategory?.category?.title === this.categoryData;
      });
    },
    limitedProducts() {
      return this.filteredProducts.slice(0, 10); // Only show the first 10 products
    },

  },
  created() {

  }
};
</script>


<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.swiper-container {
  position: relative;
  margin: 2rem;
  padding: 0 1rem;
}

.swiper {
  padding: 2rem 3rem;

}

.swiper-slide {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  transition: transform 0.3s ease;
  border: none;
  }


.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  filter: drop-shadow(0px 208px 58px rgba(0, 0, 0, 0.00)) drop-shadow(0px 133px 53px rgba(0, 0, 0, 0.01)) drop-shadow(0px 75px 45px rgba(0, 0, 0, 0.05)) drop-shadow(0px 33px 33px rgba(0, 0, 0, 0.09)) drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.10));
}

.card-body {
  padding: 2rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-text {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  text-transform: capitalize;
  margin: 0;
}

.badge {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #FFF;
  background: #FFAB0A;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
}

.swiper-button-next,
.swiper-button-prev {
  color: #ffffff;
  background: rgb(16 16 16 / 65%);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: rgb(16 16 16 / 78%);
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1rem;
}

@media only screen and (max-width: 375px) {
  .swiper-container {
    padding: 0px;
    margin: 0;
  }

  .swiper {
    padding: 2rem;
  }

  .card-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .swiper-container {
    padding: 0px;
    margin: 0;
  }

  .swiper {
    padding: 2rem;
  }
    .card-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .swiper-container {
    padding: 0px;
    margin: 0;
  }

  .swiper {
    padding: 2rem;
  }
    .card-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .swiper-pagination {
    display: none;
  }
}
</style>
