<template>
  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <!-- Loop through each carousel item in the array -->
      <div
          class="carousel-item active"
      >
        <img
            :src="mainImage"
            :alt="imageAlt"
            style="width: 100%;"
            class="img_main d-none d-lg-block"
        />
        <img
            :src="imageOne"
            :alt="imageAlt"
            style="width: 100%;"
            class="img_one d-none"
        />
        <img
            :src="imageTwo"
            :alt="imageAlt"
            style="width: 100%;"
            class="img_two d-none"
        />
        <img
            :src="imageThree"
            :alt="imageAlt"
            style="width: 100%;"
            class="img_three d-none"
        />
        <img
            :src="imageFour"
            :alt="imageAlt"
            style="width: 100%;"
            class="img_four d-none"
        />
        <div class="carousel-caption">
          <h2>{{ title }}</h2>
          <p>{{ description }}</p>
          <a class="d-none d-lg-block" :href="link">
            <div class="d-flex align-items-center g-2" style="gap:10px">
              Show More
              <svg class="d-block d-md-none" xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                   viewBox="0 0 10 10"
                   fill="none">
                <path d="M1 9L9 1M9 1V9M9 1H1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="d-none d-md-block" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                   viewBox="0 0 12 12"
                   fill="none">
                <path d="M1 11L11 1M11 1V11M11 1H1" stroke="url(#paint0_linear_204_400)" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <defs>
                  <linearGradient id="paint0_linear_204_400" x1="6" y1="1" x2="6" y2="110.5"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="0.471976" stop-color="#0C1711"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HighNetwork",
  props: {
    mainImage: {
      type: String,
      required: true,
    },
    imageOne: {
      type: String,
      required: true,
    },
    imageTwo: {
      type: String,
      required: true,
    },
    imageThree: {
      type: String,
      required: true,
    },
    imageFour: {
      type: String,
      required: true,
    },
    imageAlt: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.carousel-caption {
  top: 20%;
  text-align: start;
  left: 8%;
  right: 8%;
}

.carousel-caption h2 {
  color: #FFF;
  text-align: start;
  font-family: 'Poppins', sans-serif;
  font-size:  40px;
  font-style: normal;
  font-weight: 600;
  line-height:  48px;
  letter-spacing:  0.8px;
  text-transform: uppercase;
}

.carousel-caption p {
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 40px;
}

.carousel-caption a {
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  padding: 13px 24px;
  text-decoration: none;
  border-radius: 40px;
  border: 2px solid #FFF;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
}

@media only screen and (max-width: 375px) {
  .img_one {
    display: block !important;
  }

  .carousel-caption h2 {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 100% */
  }

  .carousel-caption p {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom: 40px;
  }

  .carousel-caption a {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    padding: 10px 24px;
    text-decoration: none;
    border-radius: 40px;
    border: 2px solid #FFF;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 10px;
  }

}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .img_two {
    display: block !important;
  }

  .carousel-caption h2 {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 100% */
  }

  .carousel-caption p {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom: 40px;
  }

  .carousel-caption a {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    padding: 10px 24px;
    text-decoration: none;
    border-radius: 40px;
    border: 2px solid #FFF;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 10px;
  }

}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .img_three {
    display: block !important;
  }
    .carousel-caption h2 {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 100% */
  }

  .carousel-caption p {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom: 40px;
  }

  .carousel-caption a {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    padding: 10px 24px;
    text-decoration: none;
    border-radius: 40px;
    border: 2px solid #FFF;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 10px;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .img_four {
    display: block !important;
  }
    .carousel-caption h2 {
    color: #FFF;
    font-family:'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 100% */
  }

  .carousel-caption p {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom: 40px;
  }

  .carousel-caption a {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    padding: 10px 24px;
    text-decoration: none;
    border-radius: 40px;
    border: 2px solid #FFF;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 10px;
  }

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .carousel-caption {
    top: 10%;
  }

  .carousel-caption h2 {
    font-size: 30px;
  }

  .carousel-caption p{
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .carousel-caption {
    top: 20%;
  }

  .carousel-caption h2 {
    font-size: 30px;
  }

  .carousel-caption p{
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1440px) {

}
</style>
