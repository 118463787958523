
To make the headers and specifications dynamic props that can be passed as strings (e.g., JSON-encoded strings), you can adjust your component script as follows:

Updated Component
vue
Copy code
<template>
  <div class="specification" id="scrollspyHeading2">
    <div class="table-container">
      <table class="spec-table">
        <thead>
          <tr>
            <th v-for="header in parsedHeaders" :key="header">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in parsedSpecifications"
            :key="index"
            :class="{ 'even-row': index % 2 === 1 }"
          >
            <template v-if="row.rowSpan">
              <td :rowspan="row.rowSpan">{{ row.key }}</td>
              <td>{{ row.values[0] }}</td>
            </template>
            <template v-else-if="row.subRow">
              <td>{{ row.values[0] }}</td>
            </template>
            <template v-else>
              <td>{{ row.key }}</td>
              <td>{{ row.values[0] }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSpecifications2",
  props: {
    headers: {
      type: String, // JSON string for headers
      required: true,
    },
    specifications: {
      type: String, // JSON string for specifications
      required: true,
    },
  },
  computed: {
    // Parse headers string into an array
    parsedHeaders() {
      try {
        return JSON.parse(this.headers);
      } catch (error) {
        console.error("Invalid JSON for headers:", error);
        return [];
      }
    },
    // Parse specifications string into an array
    parsedSpecifications() {
      try {
        return JSON.parse(this.specifications);
      } catch (error) {
        console.error("Invalid JSON for specifications:", error);
        return [];
      }
    },
  },
};
</script>


<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
.specification {
  padding: 80px;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.spec-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.spec-table th,
.spec-table td {
  padding: 15px;
  text-align: left;

}

.spec-table th {
  background-color: #f2f2f2;
  width: 30%;
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 128%; /* 23.04px */
  letter-spacing: 0.54px;
}

.spec-table td {
  background-color: #ffffff;
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 128%; /* 17.92px */
  letter-spacing: 0.42px;
}

.spec-table tr:nth-child(even) th,
.spec-table tr:nth-child(even) td {
  background-color: #f9f9f9;
}

@media only screen and (max-width: 375px) {
  .specification {
    padding: 20px !important;
  }

  .spec-table th {
    font-size: 16px;

  }

  .spec-table td {
    font-size: 12px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .specification {
    padding: 20px !important;
  }
    .spec-table th {
    font-size: 16px;

  }

  .spec-table td {
    font-size: 12px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .specification {
    padding: 20px !important;
  }
    .spec-table th {
    font-size: 16px;

  }

  .spec-table td {
    font-size: 12px;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .specification {
    padding: 40px !important;
  }

}

@media only screen and  (min-width: 992px) and (max-width: 1199px) {

}

</style>
