<template>
  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div
          class="carousel-item active"
      >

        <img
            :src="mainImage"
            :alt="imageAlt"
            style="width: 100%;"
            class="img_main d-none d-lg-block"
        />
        <img
            :src="imageOne"
            :alt="imageAlt"
            style="width: 100%;"
            class="img_one d-none"
        />
        <img
            :src="imageTwo"
            :alt="imageAlt"
            style="width: 100%;"
            class="img_two d-none"
        />
        <img
            :src="imageThree"
            :alt="imageAlt"
            style="width: 100%;"
            class="img_three d-none"
        />
        <img
            :src="imageFour"
            :alt="imageAlt"
            style="width: 100%;"
            class="img_four d-none"
        />
        <div class="carousel-caption">
          <h2>{{ title }}</h2>
          <p>{{ description }}</p>
          <a :href="link" class="d-none d-md-inline-block">
            Show more
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path d="M1 11L11 1M11 1V11M11 1H1" stroke="url(#paint0_linear_204_400)" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <defs>
                <linearGradient id="paint0_linear_204_400" x1="6" y1="1" x2="6" y2="110.5"
                                gradientUnits="userSpaceOnUse">
                  <stop stop-color="white"/>
                  <stop offset="0.471976" stop-color="#0C1711"/>
                </linearGradient>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  name: 'NetworkingSolution',
  props: {
    mainImage:String,
    imageOne:String,
    imageTwo:String,
    imageThree:String,
    imageFour:String,
    imageAlt:String,
    title: String,
    description: String,
    link: String,
  }
};
</script>

<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
.carousel-caption {
  top: 25%;
}

.carousel-caption h2 {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.carousel-caption p {
  color: #FFF;
  text-align: center;
  font-family:'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 40px;
}

.carousel-caption a {
  color: #FFF;
  text-align: right;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  padding: 13px 24px;
  text-decoration: none;
  border-radius: 40px;
  border: 2px solid #FFF;
  display: inline-block;
}

@media only screen and (max-width: 375px) {
  .img_one {
    display: block !important;
  }

  .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .carousel-caption p {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    margin-bottom: 40px;
  }

  .carousel-caption a {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    padding: 13px 24px;
    text-decoration: none;
    border-radius: 40px;
    border: 2px solid #FFF;
    display: inline-block;
  }

  .carousel-caption{
    top: 15%;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .img_two {
    display: block !important;
  }
    .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .carousel-caption p {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    margin-bottom: 40px;
  }
  .carousel-caption{
    top: 15%;
  }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .img_three {
    display: block !important;
  }
    .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .carousel-caption p {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    margin-bottom: 40px;
  }
  .carousel-caption{
    top: 15%;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .img_four{
    display: block !important;
  }
    .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .carousel-caption p {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    margin-bottom: 40px;
  }

  .carousel-caption a {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    padding: 13px 24px;
    text-decoration: none;
    border-radius: 40px;
    border: 2px solid #FFF;
    display: inline-block;
  }
  .carousel-caption{
    top: 25%;
  }
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .carousel-caption {
    top: 20%;
    bottom: 0rem;
  }

  .carousel-caption h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1440px) {

}
</style>

