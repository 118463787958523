<template>
  <div>
    <nav aria-label="breadcrumb" class="d-none d-lg-block">
      <ol class="breadcrumb">
        <li class="breadcrumb-item d-flex align-items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
            <path d="M1 5.8125L8.875 1L16.75 5.8125M15 8.875V14.475C15 14.765 14.765 15 14.475 15H3.275C2.98505 15 2.75 14.765 2.75 14.475V8.875" stroke="#0F1E2A" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <a href="#">Home</a>
        </li>
        <li class="breadcrumb-item"><a href="#">About</a></li>
      </ol>
    </nav>

    <div class="py-5 story_area overflow-hidden" style="padding: 80px">
      <div class="text-center d-flex flex-column align-items-center mb-4">
        <h2 class="story-heading">
          <span class="me-2">—</span>
          Since 1982 Our Story
          <span class="ms-2">—</span>
        </h2>
        <p class="text-muted " style="max-width: 650px">
          Safenet is Bangladesh's only homegrown brand specializing in structured LAN <br> infrastructure, providing
          reliable networking solutions since 2010.
        </p>
      </div>

      <div class="position-relative" style="margin-bottom: 100px;">
        <div class="circular-text mb-5">
          <div class="container">
            <div class="circular-text-container">
              <div class="circular-text">
                <span v-for="(char, index) in circularText" :key="index"
                      :style="{ transform: `rotate(${index * 10}deg)` }">
                  {{ char }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div type="button" class="play-button" data-bs-toggle="modal" data-bs-target="#exampleModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
            <path d="M1 1L15 10L1 19V1Z" fill="#222222" stroke="#0F172A" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" style="max-width: 50%">
        <div class="modal-content" style="background: transparent; border: none">
          <div class="modal-body w-100" style="height: 550px">
            <iframe
                ref="videoIframe"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/GJWzKzndgRs?si=0utfNAeoL9U9p9ZT&enablejsapi=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
      </div>

      <div class="row align-items-center mb-5">
        <div class="col-12 col-lg-6">
          <div class="d-flex align-items-baseline mb-4">
            <span class="big-number">24</span>
            <h3 class="ms-3 mb-0 year">Years</h3>
          </div>
          <h4 class="mb-3 lan">Bangladesh's First Structured LAN Infrastructure Brand</h4>
          <p class="text-muted lan-text">
            "Digital Bangladesh" with this slogan Bangladesh is fast pacing toward the title of "Developing
            country". Safenet - aligned its core goal with this concept and contributing every possible aspect of
            "Digital Bangladesh" of the country. Safenet, today is which services and serving high-quality
            components calculated to match the needs in the different vertical markets of the ICT industry.
          </p>
        </div>
        <div class="col-12 col-lg-6">
          <div class="bg-light" style="height: 400px;">
            <img class="w-100 h-100" style="border-radius: 15px" src="https://safenet.bitquintet.com/media/images/Company_related_img.webp">
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center flex-column flex-lg-row align-items-center  ">
        <div class="circle-button d-flex flex-column" style="padding: 30px">
          <h4>MISSION</h4>
          <p class="">Offer Such Products that it will take on an identity by itself and stand for loyalty. trust, faith
            along at
            its finest.</p>
        </div>
        <div class="circle-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="88" height="84" viewBox="0 0 88 84" fill="none">
            <path
                d="M42.5734 2.00861C43.0224 0.626645 44.9776 0.626641 45.4266 2.00861L54.3011 29.3217C54.6358 30.3517 55.5957 31.0491 56.6788 31.0491H85.3975C86.8505 31.0491 87.4547 32.9086 86.2791 33.7627L63.0452 50.6431C62.169 51.2797 61.8024 52.4081 62.1371 53.4382L71.0116 80.7513C71.4607 82.1332 69.8789 83.2824 68.7034 82.4283L45.4695 65.5479C44.5933 64.9113 43.4068 64.9113 42.5305 65.5479L19.2966 82.4283C18.1211 83.2824 16.5394 82.1332 16.9884 80.7513L25.8629 53.4382C26.1976 52.4081 25.831 51.2797 24.9548 50.6431L1.72086 33.7627C0.545291 32.9086 1.14945 31.0491 2.60254 31.0491H31.3212C32.4043 31.0491 33.3642 30.3517 33.6989 29.3217L42.5734 2.00861Z"
                fill="url(#paint0_linear_314_1390)" stroke="url(#paint1_linear_314_1390)"/>
            <defs>
              <linearGradient id="paint0_linear_314_1390" x1="44.0468" y1="-4" x2="44.0469" y2="96"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#313131"/>
                <stop offset="1" stop-color="#1E1E1E"/>
              </linearGradient>
              <linearGradient id="paint1_linear_314_1390" x1="71.5" y1="83" x2="17.5444" y2="4.24798"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#0F0F0F"/>
                <stop offset="0.4" stop-color="#2C2C2C"/>
                <stop offset="1" stop-color="#848484"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="circle-button second_btn d-flex flex-column" style="padding: 30px">
          <h4>VISION</h4>
          <p class="">Perfection is about results; excellence is about the process. Safenet delivers top-quality products and components with its expert, reliable approach.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      circularText: 'DURABLE•OUR BIG COLLECTION•FLEXIBLE•'
    }
  },
  methods: {
    stopVideo() {
      if (this.$refs.videoIframe) {
        const iframeSrc = this.$refs.videoIframe.src
        this.$refs.videoIframe.src = iframeSrc
      }
    }
  },
  mounted() {
    const modal = document.getElementById('exampleModal')
    if (modal) {
      modal.addEventListener('hidden.bs.modal', this.stopVideo)
    }
  }
}
</script>

<style scoped>
@font-face {
    font-family: 'Poppins';
    src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}



.modal-dialog{
  margin-top: 7%;
}
.story-heading {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 52px */
  letter-spacing: 0.2px;
}

.circle-button h4 {
  color: #222;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 41.6px */
  letter-spacing: 1.6px;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.circle-button {
  position: relative;
  overflow: hidden;
}

.circle-button p {
  color: #262626;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: absolute;
  bottom: -90px;
  transform: translateY(20px);
  opacity: 0;
  padding: 30px;
}

.circle-button:hover p {
  transform: translateY(-110px);
  opacity: 1;
}

.circle-button:hover h4 {
  transform: translateY(-20px);
}

.second_btn:hover h4 {
  transform: translateY(-40px) !important;
}
.second_btn:hover p{
  transform: translateY(-100px) !important;
}

.breadcrumb {
  background: #F6F6F6;
  padding: 20px 80px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '';

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: solid #1E1E1E;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 0px;
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
  height: 0;
  width: 0;
}

.circular-text {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 2rem auto;
}

.circular-text span {
  color: #222;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.big-number {
  font-size: 150px;
  font-style: normal;
  font-weight: 700;
  line-height: 150px;
  background: linear-gradient(117deg, #1AE5FE 8.83%, #105BCC 108.23%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.circle-button {
  width: 300px;
  height: 300px;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  transition: all 0.3s ease;
  color: #222;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 1.6px;
}

.circle-button:hover {
  background: #f8f9fa;
  cursor: pointer;
}

.story-heading {
  background: linear-gradient(91deg, #1AE5FE 14.22%, #105BCC 86.23%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.2px;
  margin-bottom: 16px;
}

.text-muted {
  color: #000;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: 0.08px;
  margin-bottom: 50px;
}

.circular-text-container {
  width: 300px;
  height: 300px;
  margin: 2rem auto;
}

.circular-text {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: rotate 40s linear infinite;
}

.circular-text span {
  position: absolute;
  left: 50%;
  font-size: 20px;
  transform-origin: 0 150px;
  text-transform: uppercase;
}

.year {
  color: #064D85;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.16px;
}

.lan {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
  letter-spacing: 0.16px;
}

.lan-text {
  color: #262626;
  text-align: justify;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}

@media only screen and (max-width: 375px) {
  .story-heading {
    background: linear-gradient(91deg, #1AE5FE 14.22%, #105BCC 86.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 26px */
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }

  .text-muted {
    color: #262626;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
    margin-bottom: 50px;
  }

  .story_area {
    padding: 20px !important;
  }

  .lan {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    letter-spacing: 0.1px;
  }

  .modal-dialog {
    max-width: 90% !important;
    margin: 50% auto 0;

  }

  .modal-body {
    height: 400px !important;
  }

}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .story-heading {
    background: linear-gradient(91deg, #1AE5FE 14.22%, #105BCC 86.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 26px */
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }

  .text-muted {
    color: #262626;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
    margin-bottom: 50px;
  }

  .story_area {
    padding: 20px !important;
  }

  .lan {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    letter-spacing: 0.1px;
  }

  .modal-dialog {
    max-width: 90% !important;
    margin: 40% auto 0;

  }

  .modal-body {
    height: 400px !important;
  }

}

@media only screen and (min-width: 426px) and (max-width: 767px) {

  .story-heading {
    background: linear-gradient(91deg, #1AE5FE 14.22%, #105BCC 86.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 26px */
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }

  .text-muted {
    color: #262626;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
    margin-bottom: 50px;
  }

  .story_area {
    padding: 20px !important;
  }

  .lan {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    letter-spacing: 0.1px;
  }

  .modal-dialog {
    max-width: 90% !important;
    margin: 20% auto 0;

  }

  .modal-body {
    height: 400px !important;
  }

}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .story_area {
    padding: 40px !important;
  }

  .modal-dialog {
    max-width: 80% !important;
    margin: 7% auto 0;

  }

  .modal-body {
    height: 450px !important;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .story_area {
    padding: 50px !important;
  }

  .modal-dialog {
    max-width: 60% !important;
    margin: 7% auto 0;

  }

  .modal-body {
    height: 500px !important;
  }
}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1440px) {

}
</style>