<template>
  <div>
    <!-- Carousel -->
    <div id="carouselExampleCaptions" class="carousel slide">
      <div class="carousel-inner">
        <div class="carousel-item" v-for="(item, index) in paginatedList" :key="index">
          <img
            :src="item.mainImage"
            :alt="item.imageAlt"
            style="width: 100%;"
            class="img_main d-none d-lg-block"
          />
          <div class="carousel-caption">
            <h2>{{ item.title }}</h2>
            <p>{{ item.description }}</p>
            <a href="#">
              Show more
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M1 11L11 1M11 1V11M11 1H1" stroke="url(#paint0_linear_204_400)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <defs>
                  <linearGradient id="paint0_linear_204_400" x1="6" y1="1" x2="6" y2="110.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="0.471976" stop-color="#0C1711"/>
                  </linearGradient>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="product_item_area" style="padding: 20px 80px;">
      <!-- Breadcrumb -->
      <nav aria-label="breadcrumb" class="d-none d-lg-block">
        <ol class="breadcrumb">
          <li class="breadcrumb-item d-flex align-items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
              <path
                  d="M1 5.8125L8.875 1L16.75 5.8125M15 8.875V14.475C15 14.765 14.765 15 14.475 15H3.275C2.98505 15 2.75 14.765 2.75 14.475V8.875"
                  stroke="#0F1E2A" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item"><a href="#">Products</a></li>
        </ol>
      </nav>

      <div class="row ">
        <!-- Sidebar Filter -->
        <aside class="col-md-4 d-none d-lg-block">
          <div class="left_bar">
            <div class="filter d-flex justify-content-between">
              <h5>Filter</h5>
              <button class="clear_btn" @click="clearAllFilters">Clear All</button>
            </div>
            <div class="mb-3">
              <div class="d-flex gap-2 product_list" style="margin-bottom: 16px">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path
                      d="M1 17C1 15.46 1 14.69 1.347 14.124C1.541 13.807 1.807 13.541 2.124 13.347C2.689 13 3.46 13 5 13C6.54 13 7.31 13 7.876 13.347C8.193 13.541 8.459 13.807 8.653 14.124C9 14.689 9 15.46 9 17C9 18.54 9 19.31 8.653 19.877C8.459 20.193 8.193 20.459 7.876 20.653C7.311 21 6.54 21 5 21C3.46 21 2.69 21 2.124 20.653C1.80735 20.4593 1.54108 20.1934 1.347 19.877C1 19.31 1 18.54 1 17ZM13 17C13 15.46 13 14.69 13.347 14.124C13.541 13.807 13.807 13.541 14.124 13.347C14.689 13 15.46 13 17 13C18.54 13 19.31 13 19.877 13.347C20.193 13.541 20.459 13.807 20.653 14.124C21 14.689 21 15.46 21 17C21 18.54 21 19.31 20.653 19.877C20.4589 20.1931 20.1931 20.4589 19.877 20.653C19.31 21 18.54 21 17 21C15.46 21 14.69 21 14.124 20.653C13.8073 20.4593 13.5411 20.1934 13.347 19.877C13 19.31 13 18.54 13 17ZM1 5C1 3.46 1 2.69 1.347 2.124C1.541 1.807 1.807 1.541 2.124 1.347C2.689 1 3.46 1 5 1C6.54 1 7.31 1 7.876 1.347C8.193 1.541 8.459 1.807 8.653 2.124C9 2.689 9 3.46 9 5C9 6.54 9 7.31 8.653 7.876C8.459 8.193 8.193 8.459 7.876 8.653C7.311 9 6.54 9 5 9C3.46 9 2.69 9 2.124 8.653C1.80724 8.45904 1.54096 8.19277 1.347 7.876C1 7.311 1 6.54 1 5ZM13 5C13 3.46 13 2.69 13.347 2.124C13.541 1.807 13.807 1.541 14.124 1.347C14.689 1 15.46 1 17 1C18.54 1 19.31 1 19.877 1.347C20.193 1.541 20.459 1.807 20.653 2.124C21 2.689 21 3.46 21 5C21 6.54 21 7.31 20.653 7.876C20.459 8.193 20.193 8.459 19.877 8.653C19.31 9 18.54 9 17 9C15.46 9 14.69 9 14.124 8.653C13.8072 8.45904 13.541 8.19277 13.347 7.876C13 7.311 13 6.54 13 5Z"
                      stroke="#222222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <h6>Product List</h6>
              </div>
              <div class="accordion" id="accordionExample">
                <div v-for="(category, index) in categories" :key="index" class="accordion-item">
                  <h2 class="accordion-header" :id="'heading' + index">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            :data-bs-target="'#collapse' + index" :aria-expanded="index === 0"
                            :aria-controls="'collapse' + index">
                      {{ category.name }} <span class="badge__ ms-auto">{{ category.count }}</span>
                    </button>
                  </h2>
                  <div :id="'collapse' + index" class="accordion-collapse collapse" :class="{ show: index === 0 }"
                       :aria-labelledby="'heading' + index" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <ul class="list-group list-group-flush">
                        <li v-for="(subcategory, subIndex) in category.subcategories" :key="subIndex"
                            class="list-group-item">
                          <a href="#">{{ subcategory.name }} <span class="badge__">{{ subcategory.count }}</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="d-flex product_list gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                      d="M10 20C4.49 20 0 15.51 0 10C0 4.49 4.49 0 10 0C15.51 0 20 4.04 20 9C20 12.31 17.31 15 14 15H12.23C11.95 15 11.73 15.22 11.73 15.5C11.73 15.62 11.78 15.73 11.86 15.83C12.27 16.3 12.5 16.89 12.5 17.5C12.5 18.163 12.2366 18.7989 11.7678 19.2678C11.2989 19.7366 10.663 20 10 20ZM10 2C5.59 2 2 5.59 2 10C2 14.41 5.59 18 10 18C10.28 18 10.5 17.78 10.5 17.5C10.4969 17.3703 10.4472 17.2461 10.36 17.15C9.95 16.69 9.73 16.1 9.73 15.5C9.73 14.837 9.99339 14.2011 10.4622 13.7322C10.9311 13.2634 11.567 13 12.23 13H14C16.21 13 18 11.21 18 9C18 5.14 14.41 2 10 2Z"
                      fill="#222222"/>
                  <path
                      d="M4.5 11C5.32843 11 6 10.3284 6 9.5C6 8.67157 5.32843 8 4.5 8C3.67157 8 3 8.67157 3 9.5C3 10.3284 3.67157 11 4.5 11Z"
                      fill="black"/>
                  <path
                      d="M7.5 7C8.32843 7 9 6.32843 9 5.5C9 4.67157 8.32843 4 7.5 4C6.67157 4 6 4.67157 6 5.5C6 6.32843 6.67157 7 7.5 7Z"
                      fill="black"/>
                  <path
                      d="M12.5 7C13.3284 7 14 6.32843 14 5.5C14 4.67157 13.3284 4 12.5 4C11.6716 4 11 4.67157 11 5.5C11 6.32843 11.6716 7 12.5 7Z"
                      fill="black"/>
                  <path
                      d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
                      fill="black"/>
                </svg>
                <h6>Quality</h6>
              </div>
              <div class="d-flex flex-column" style="padding: 20px; background: white; gap: 20px">
                <div v-for="quality in qualities" :key="quality" class="form-check">
                  <input class="form-check-input" type="checkbox" :id="quality" v-model="selectedQualities">
                  <label class="form-check-label" :for="quality">{{ quality }}</label>
                </div>
              </div>
            </div>

          </div>
        </aside>

        <!-- Product Grid -->
        <main class="col-md-12 col-lg-8">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <p class="d-none d-lg-block">
              <span style="color: #222; font-size: 24px; font-weight: 600; line-height: 20px; letter-spacing: 0.72px">
                {{ filteredProducts.length }}
              </span> items found
            </p>
            <div class="d-block d-lg-none">
              <div class="filter_box d-flex align-items-center gap-2">
                <a class="" href="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path
                        d="M1.66666 1.09375H12.3332C12.7014 1.09375 12.9999 1.39217 12.9999 1.76032L13 2.81752C13.0001 2.99437 12.9298 3.16397 12.8047 3.28901L8.52847 7.56505C8.40347 7.69004 8.33321 7.85964 8.33321 8.03644V12.2397C8.33321 12.6734 7.92561 12.9917 7.50489 12.8865L6.17158 12.5532C5.87478 12.479 5.66659 12.2123 5.66659 11.9064V8.03644C5.66659 7.85964 5.59635 7.69004 5.47133 7.56505L1.19526 3.28897C1.07023 3.16396 1 2.99439 1 2.81758V1.76041C1 1.39223 1.29847 1.09375 1.66666 1.09375Z"
                        stroke="#3C3C3D" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </a>
                <h2 class="mb-0">Filter</h2>
              </div>
            </div>
            <select v-model="sortBy" class="form-select w-auto sort-by">
              <option value="default">Default sorting</option>
              <option value="price-low-high">Price: Low to High</option>
              <option value="price-high-low">Price: High to Low</option>
              <option value="popularity">Popularity</option>
            </select>
          </div>

          <div class="product-grid">
            <div v-for="product in paginatedProducts" :key="product.id" class="card">
              <div class="card-image">
                <span v-if="product.badge"
                      :class="['badge', `bg-${product.badge.type}`, 'position-absolute', 'top-0', 'start-0', 'm-2']">
                  {{ product.badge.text }}
                </span>
                <img :src="product.image" class="card-img-top" :alt="product.name">
              </div>
              <div class="card-body text-start">
                <h2 class="card-text">{{ product.name }}</h2>
                <p class="releted_card">{{ product.releted }}</p>
              </div>
            </div>
          </div>

          <!-- Pagination -->
          <div class="d-flex align-items-center mt-4">
            <div class="items-per-page d-none d-lg-block">
              <select  class="form-select">
                <option v-for="option in itemsPerPageOptions" :key="option" :value="option">{{ option }}</option>
              </select>
              <span class="ms-2 text-nowrap">Items per page</span>
            </div>

            <nav aria-label="Page navigation" class="ms-auto">
              <ul class="pagination mb-0 d-flex" style="gap: 15px">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)" aria-label="Previous">
                    <span aria-hidden="true">&laquo; Previous</span>
                  </a>
                </li>
                <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
                  <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)" aria-label="Next">
                    <span aria-hidden="true">Next &raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list', 'filters', 'sortOptions', 'itemsPerPage', 'products', 'categories'],
  data() {
    return {
      selectedFilter: 'All',
      selectedSort: 'alphabetical',
      currentPage: 1,
      filteredProducts: [],
    };
  },
  computed: {
    parsedList() {
      return JSON.parse(this.list);
    },
    parsedFilters() {
      return JSON.parse(this.filters);
    },
    parsedSortOptions() {
      return JSON.parse(this.sortOptions);
    },
    parsedItemsPerPage() {
      return parseInt(this.itemsPerPage, 10);
    },
    filteredList() {
      if (this.selectedFilter === 'All') return this.parsedList;
      return this.parsedList.filter(item => item.category === this.selectedFilter);
    },
    sortedList() {
      if (this.selectedSort === 'alphabetical') {
        return [...this.filteredList].sort((a, b) => a.title.localeCompare(b.title));
      } else if (this.selectedSort === 'newest') {
        return [...this.filteredList].sort((a, b) => new Date(b.date) - new Date(a.date));
      } else if (this.selectedSort === 'oldest') {
        return [...this.filteredList].sort((a, b) => new Date(a.date) - new Date(b.date));
      }
      return this.filteredList;
    },
    paginatedList() {
      const start = (this.currentPage - 1) * this.parsedItemsPerPage;
      return this.sortedList.slice(start, start + this.parsedItemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.sortedList.length / this.parsedItemsPerPage);
    }
  },
  methods: {
    applyFilter() {
      this.currentPage = 1; // Reset to first page on filter change
    },
    applySort() {
      this.currentPage = 1; // Reset to first page on sort change
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  }
}
</script>

<!--<script setup>-->
<!--import {ref, computed} from 'vue'-->

<!--// Sample data-->
<!--const categories = ref([-->
<!--  {-->
<!--    name: 'Copper Infrastructure', count: 23, subcategories: [-->
<!--      {name: 'Key Stone Jack', count: 7},-->
<!--      {name: 'Patch Panel', count: 5},-->
<!--      {name: 'Cable', count: 11}-->
<!--    ]-->
<!--  },-->
<!--  {-->
<!--    name: 'Fiber Optic Infrastructure', count: 15, subcategories: [-->
<!--      {name: 'Connectors', count: 4},-->
<!--      {name: 'Cables', count: 6},-->
<!--      {name: 'Patch Panels', count: 5}-->
<!--    ]-->
<!--  },-->
<!--  {-->
<!--    name: 'Network & Server Racks', count: 8, subcategories: [-->
<!--      {name: 'Wall Mount Racks', count: 3},-->
<!--      {name: 'Floor Standing Racks', count: 5}-->
<!--    ]-->
<!--  },-->
<!--  {-->
<!--    name: 'KVM Switch & Rack Console', count: 6, subcategories: [-->
<!--      {name: 'KVM Switches', count: 3},-->
<!--      {name: 'Rack Consoles', count: 3}-->
<!--    ]-->
<!--  }-->
<!--])-->
<!--const list = ref([-->
<!--  {-->
<!--    mainImage: "http://new.artive.com.bd/media/images/Copper_Infrastructure.webp",-->
<!--    imageOne: "http://new.artive.com.bd/media/images/375x480.png",-->
<!--    imageTwo: "http://new.artive.com.bd/media/images/425x480.png",-->
<!--    imageThree: "http://new.artive.com.bd/media/images/767x520.png",-->
<!--    imageFour: "http://new.artive.com.bd/media/images/991x550.png",-->

<!--    imageAlt: "XCubeNAS 5100R - Capacity-Optimized Rackmount NAS for SMB",-->
<!--    title: "Stronger Connections",-->
<!--    description: "Efficient Copper Connectivity for Modern Networks",-->
<!--  }-->
<!--])-->

<!--const qualities = ref(['Cat6', 'Cat6A', 'Cat7', 'Cat8', 'Single Mode', 'Multi Mode', '13 Amp', '16 Amp', '32 Amp', '64 Amp', 'Wall Mount Cabinet', 'Floor Stand Cabinet'])-->
<!--const selectedQualities = ref([])-->


<!--const products = ref([-->
<!--  {-->
<!--    id: 1,-->
<!--    name: 'Cat6 SFTP Cable Solid Wire 0.57mm 23AWG (LSZH)',-->
<!--    releted: 'Strong & Reliable ',-->
<!--    image: 'http://new.artive.com.bd/media/images/Product_highlight4.webp',-->
<!--    badge: {type: 'warning', text: 'New'}-->
<!--  },-->
<!--  {-->
<!--    id: 2,-->
<!--    name: 'Cat6 UTP Cable Solid Wire 0.57mm 23AWG (LSZH)',-->
<!--    releted: 'Strong & Reliable ',-->
<!--    image: 'http://new.artive.com.bd/media/images/Product_highlight4.webp',-->
<!--    badge: {type: 'success', text: 'Popular'}-->
<!--  },-->
<!--  {-->
<!--    id: 3,-->
<!--    name: 'Cat6A SFTP Cable Solid Wire 0.57mm 23AWG (LSZH)',-->
<!--    releted: 'Strong & Reliable ',-->
<!--    image: 'http://new.artive.com.bd/media/images/Product_highlight4.webp',-->
<!--    badge: {type: 'warning', text: 'New'}-->
<!--  },-->
<!--  {-->
<!--    id: 4,-->
<!--    name: 'Cat7 SFTP Cable Solid Wire 0.57mm 23AWG (LSZH)',-->
<!--    releted: 'Strong & Reliable ',-->
<!--    image: 'http://new.artive.com.bd/media/images/Product_highlight4.webp',-->
<!--    badge: {type: 'success', text: 'Popular'}-->
<!--  },-->
<!--  {-->
<!--    id: 5,-->
<!--    name: 'Cat5e UTP Cable Solid Wire 0.57mm 23AWG (LSZH)',-->
<!--    releted: 'Strong & Reliable ',-->
<!--    image: 'http://new.artive.com.bd/media/images/Product_highlight4.webp',-->
<!--    badge: {type: 'warning', text: 'New'}-->
<!--  },-->
<!--  {-->
<!--    id: 6,-->
<!--    name: 'Cat6 FTP Cable Solid Wire 0.57mm 23AWG (LSZH)',-->
<!--    releted: 'Strong & Reliable ',-->
<!--    image: 'http://new.artive.com.bd/media/images/Product_highlight4.webp',-->
<!--    badge: {type: 'success', text: 'Popular'}-->
<!--  },-->
<!--  {-->
<!--    id: 7,-->
<!--    name: 'Cat6A UTP Cable Solid Wire 0.57mm 23AWG (LSZH)',-->
<!--    releted: 'Strong & Reliable ',-->
<!--    image: 'http://new.artive.com.bd/media/images/Product_highlight4.webp',-->
<!--    badge: {type: 'warning', text: 'New'}-->
<!--  },-->
<!--  {-->
<!--    id: 8,-->
<!--    name: 'Cat7A SFTP Cable Solid Wire 0.57mm 23AWG (LSZH)',-->
<!--    releted: 'Strong & Reliable ',-->
<!--    image: 'http://new.artive.com.bd/media/images/Product_highlight4.webp',-->
<!--    badge: {type: 'success', text: 'Popular'}-->
<!--  },-->
<!--  {-->
<!--    id: 9,-->
<!--    name: 'Cat8 SFTP Cable Solid Wire 0.57mm 23AWG (LSZH)',-->
<!--    releted: 'Strong & Reliable ',-->
<!--    image: 'http://new.artive.com.bd/media/images/Product_highlight4.webp',-->
<!--    badge: {type: 'warning', text: 'New'}-->
<!--  },-->
<!--])-->

<!--const sortBy = ref('default')-->
<!--const itemsPerPage = ref(12)-->
<!--const currentPage = ref(1)-->
<!--const itemsPerPageOptions = [10, 12, 20, 50, 100]-->

<!--const filteredProducts = computed(() => {-->
<!--  let result = products.value-->

<!--  // Apply sorting-->
<!--  if (sortBy.value === 'price-low-high') {-->
<!--    result = result.sort((a, b) => a.price - b.price)-->
<!--  } else if (sortBy.value === 'price-high-low') {-->
<!--    result = result.sort((a, b) => b.price - a.price)-->
<!--  } else if (sortBy.value === 'popularity') {-->
<!--    result = result.sort((a, b) => b.popularity - a.popularity)-->
<!--  }-->

<!--  return result-->
<!--})-->

<!--const totalPages = computed(() => Math.ceil(filteredProducts.value.length / itemsPerPage.value))-->

<!--const paginatedProducts = computed(() => {-->
<!--  const start = (currentPage.value - 1) * itemsPerPage.value-->
<!--  const end = start + itemsPerPage.value-->
<!--  return filteredProducts.value.slice(start, end)-->
<!--})-->

<!--const clearAllFilters = () => {-->
<!--  selectedQualities.value = []-->
<!--  // Reset other filters as needed-->
<!--}-->

<!--const changePage = (page) => {-->
<!--  if (page >= 1 && page <= totalPages.value) {-->
<!--    currentPage.value = page-->
<!--  }-->
<!--}-->
<!--</script>-->

<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


.carousel-caption {
  top: 30%;
}

.carousel-caption h2 {
  color: #FFF;
  font-family:'Poppins', sans-serif;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.card-text {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
}

.releted_card {
  color: #525151;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
}

.carousel-caption p {
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 40px;
}

.carousel-caption a {
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  padding: 13px 24px;
  text-decoration: none;
  border-radius: 40px;
  border: 2px solid #FFF;
  display: inline-block;
}

.breadcrumb {
  background: #F6F6F6;
  padding: 20px 80px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '';
  background-image: url("./AllProduct_Image/Vector (3).svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  width: 12px;
  height: 12px;
}

.clear_btn {
  color: #EC0101;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.8px;
  text-transform: capitalize;
  border-radius: 6px;
  border: 1px solid rgba(236, 1, 1, 0.20);
  background: rgba(255, 238, 238, 0.50);
  padding: 13px 20px;
  cursor: pointer;
}

.color-option {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
}

.card {
  position: relative;
  border: none;
  background: #f2f3f5;
}

.card img {
  max-height: 150px;
  object-fit: cover;

}

.badge {
  font-size: 0.8rem;
  padding: 0.3rem 0.5rem;
}

.left_bar {
  padding: 70px 20px;
  background: #F8F8F8;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  gap: 40px;
  display: flex;
  flex-direction: column;
}

.filter {
  padding-bottom: 16px;
  border-bottom: 1px solid #E1E1E1;
  align-items: center;
}

.filter h5 {
  color: #1E1E1E;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.8px;
  text-transform: capitalize;
  margin-bottom: 0 !important;
}

.product_list h6 {
  color: #1E1E1E;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.accordion-button {
  display: flex;
  justify-content: flex-start;
}

.accordion-button::before {
  content: "";
  border: solid #000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  margin-right: 8px;
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
  height: 0;
  width: 0;
}

.accordion-button:not(.collapsed)::before {
  transform: rotate(45deg);
}

.accordion-button::after {
  content: none;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
  box-shadow: none;
  outline: none;
}

.accordion-button:focus {
  z-index: 3;
  outline: none;
  box-shadow: none;
}

.badge__ {
  border-radius: 4px;
  background: rgba(190, 237, 242, 0.30);
  padding: 4px;
  color: #098BC2;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  text-transform: capitalize;
}

.list-group-item {
  border: none;
}

.list-group-item a {
  color: #414141;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-group {
  display: flex;
  gap: 20px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
}

.card {
  position: relative;
  border: none;
}

.card-image {
  background: #f2f3f5;
  padding: 20px;
  position: relative;
}

.card-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.pagination .page-link {
  color: #000;
}

.pagination .page-item.active .page-link {
  background-color: #000;
  color: #fff;
  border: none;
}

.items-per-page {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.dropdown-toggle {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.page-link {
  border-radius: 6px;
  border: 1px solid #E2E2E2;
  background: #FFF;
}

.filter_box {
  border-radius: 4px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  padding: 10px 14px;
}

.filter_box h2 {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}

@media only screen and (max-width: 375px) {
  .img_one {
    display: block !important;
  }

  .product_item_area {
    padding: 20px 20px !important;
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(173px, 1fr));
    gap: 20px;
  }

  .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  .carousel-caption p {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    margin-bottom: 40px;
  }

  .carousel-caption a {
    color: #FFF;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    padding: 13px 24px;
    text-decoration: none;
    border-radius: 40px;
    border: 2px solid #FFF;
    display: inline-block;
  }

}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .img_two {
    display: block !important;
  }

  .product_item_area {
    padding: 20px 20px !important;
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(183px, 1fr));
    gap: 20px;
  }

  .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  .carousel-caption p {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    margin-bottom: 40px;
  }

  .carousel-caption a {
    color: #FFF;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    padding: 13px 24px;
    text-decoration: none;
    border-radius: 40px;
    border: 2px solid #FFF;
    display: inline-block;
  }

  .form-select {
    font-size: 0.8rem;
  }

}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .img_three {
    display: block !important;
  }

  .form-select {
    font-size: 0.8rem;
  }

  .product_item_area {
    padding: 20px 20px !important;
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 20px;
  }

  .carousel-caption h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  .carousel-caption p {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    margin-bottom: 40px;
  }

  .carousel-caption a {
    color: #FFF;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    padding: 13px 24px;
    text-decoration: none;
    border-radius: 40px;
    border: 2px solid #FFF;
    display: inline-block;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .img_four {
    display: block !important;
  }

  .product_item_area {
    padding: 20px 40px !important;
  }


}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1440px) {

}

</style>