<template>
  <div class="footer ">
    <div class="get_connect">
      <div id="carouselExampleCaptions" class="carousel slide w-100 h-100">
        <div class="carousel-inner w-100 h-100">
          <div class="carousel-item active w-100 h-100">
            <img :src="carouselImage" class="d-none d-lg-block w-100 h-100" alt="...">
            <img :src="carouselImage_two" class="d-block d-lg-none w-100 h-100" alt="...">
            <div class="carousel-caption w-100 h-100">
              <div class="w-50 caption_head">
                <h2>{{ carouselHeading }}</h2>
                <p>{{ carouselText }}</p>
              </div>
              <div class="w-50 d-flex justify-content-end caption_input">
                <div class="input-group">
                  <input
                      type="text"
                      class="form-control"
                      :placeholder="emailPlaceholder"
                      v-model="email"
                      aria-label="Recipient's email"
                      aria-describedby="basic-addon2"
                  >
                  <button type="submit" class="input-group-text" id="basic-addon2" @click="joinNewsletter">JOIN</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex container-xxl m-xxl-auto justify-content-between main_part">
      <div class="d-flex flex-column justify-content-start gap-4 first_part" style="width: 30%;">
        <img :src="logoImage" style="width: fit-content">
        <p class="footer_desc">{{ description }}</p>
        <div class="social_icon d-flex" style="gap: 12px">
          <a v-for="icon in socialIcons" :key="icon.link" :href="icon.link" target="_blank">
            <span v-html="icon.icon"></span>
          </a>
        </div>
      </div>
      <div class="d-flex align-items-start justify-content-around gap-2 second_part" style="width: 70%;">
        <div class="d-flex flex-column justify-content-around w-100">
          <div class="d-flex justify-content-around w-100">
            <div v-for="(section, index) in sections" :key="index" class="footer_items">
              <h2>{{ section.title }}</h2>
              <ul>
                <li v-for="item in section.items" :key="item.text"><a :href="item.link">{{ item.text }}</a></li>
              </ul>
            </div>
          </div>
          <div class="d-flex justify-content-center flex-wrap gap-4">
            <img v-for="(image, index) in images" :key="index" :src="image" alt="Image"/>
          </div>


        </div>
        <div class="call_area">
          <div class="d-flex flex-row flex-lg-column justify-content-around " style="gap: 30px">
            <div class="d-flex flex-column flex-lg-row align-items-center gap-3">
              <div v-html="phoneIcon"></div>
              <div class="d-flex flex-column">
                <div v-for="phone in contactNumbers" :key="phone">
                  <p class="mb-0 phone_email">{{ phone }}</p>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column flex-lg-row align-items-center gap-3">
              <div v-html="emailIcon"></div>
              <div class="d-flex flex-column">
                <div v-for="email in contactEmails" :key="email" class="footer_number">
                  <p class="mb-0 phone_email">{{ email }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="all_reserved">
      <h2>© 2024 SafeNet. All rights reserved.</h2>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carouselImage: "https://safenet.bitquintet.com/media/images/twit.png",
      carouselImage_two: 'https://safenet.bitquintet.com/media/images/footer_phone.png',
      carouselHeading: "Get Connected With Us",
      carouselText: "We will provide detailed information about Any Product. To stay up to date on all the latest news and offers from us.",
      emailPlaceholder: "Enter your email address",
      email: "",
      logoImage: 'https://safenet.bitquintet.com/media/images/clip.png',
      description: "Safenet is one and only Bangladeshi derivation of Structured LAN Infrastructure brand since 2010.",
      images: [
        'https://safenet.bitquintet.com/media/images/Ellipse_799.png',
        'https://safenet.bitquintet.com/media/images/Ellipse_804.png',
        'https://safenet.bitquintet.com/media/images/Ellipse_805.png',
        'https://safenet.bitquintet.com/media/images/Ellipse_806.png',
        'https://safenet.bitquintet.com/media/images/Ellipse_807.png',
      ],
      socialIcons: [
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">\n' +
              '  <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C23.2842 0 29.9999 6.71571 29.9999 15C29.9999 22.4869 24.5146 28.6924 17.3437 29.8177V19.336H20.8388L21.5039 15.0001H21.5038L21.5038 15H17.3437L17.3437 12.1863C17.3437 11.0001 17.9248 9.84385 19.7882 9.84385H21.6796V6.15245C21.6796 6.15245 21.6796 6.15245 21.6796 6.15244V6.15233C21.6796 6.15233 19.963 5.85937 18.3219 5.85937C14.8956 5.85937 12.6562 7.93592 12.6562 11.6953V15H8.84763V19.3359H8.84766V19.336H12.6562V29.8177C5.48526 28.6924 0 22.4869 0 15C0 6.71571 6.7157 0 15 0Z" fill="white"/>\n' +
              '</svg>',
          link: 'https://facebook.com'
        },
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">\n' +
              '  <path d="M15 0C6.71484 0 0 6.71484 0 15C0 23.2852 6.71484 30 15 30C23.2852 30 30 23.2852 30 15C30 6.71484 23.2852 0 15 0ZM10.8047 21.2754H7.88086V11.918H10.8047V21.2754ZM9.26367 10.7461H9.24023C8.17969 10.7461 7.49414 10.0313 7.49414 9.12305C7.49414 8.19727 8.20312 7.5 9.28125 7.5C10.3594 7.5 11.0215 8.19727 11.0449 9.12305C11.0508 10.0254 10.3652 10.7461 9.26367 10.7461ZM22.5 21.2754H19.1836V16.4355C19.1836 15.1699 18.668 14.3027 17.5254 14.3027C16.6523 14.3027 16.166 14.8887 15.9434 15.4512C15.8613 15.6504 15.873 15.9316 15.873 16.2188V21.2754H12.5859C12.5859 21.2754 12.627 12.6973 12.5859 11.918H15.873V13.3887C16.0664 12.7441 17.1152 11.8301 18.791 11.8301C20.8711 11.8301 22.5 13.1777 22.5 16.0723V21.2754Z" fill="white"/>\n' +
              '</svg>',
          link: 'https://twitter.com'
        },
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">\n' +
              '  <path d="M13.002 17.8887L17.9121 15.0059L13.002 12.123V17.8887Z" fill="white"/>\n' +
              '  <path d="M15 0C6.71484 0 0 6.71484 0 15C0 23.2852 6.71484 30 15 30C23.2852 30 30 23.2852 30 15C30 6.71484 23.2852 0 15 0ZM22.5 17.6836C22.5 20.2676 19.916 20.2676 19.916 20.2676H10.084C7.5 20.2676 7.5 17.6836 7.5 17.6836V12.3223C7.5 9.73828 10.084 9.73828 10.084 9.73828H19.916C22.5 9.73828 22.5 12.3223 22.5 12.3223V17.6836Z" fill="white"/>\n' +
              '</svg>',
          link: 'https://linkedin.com'
        }
      ],
      sections: [
        {
          title: "Category",
          items: [{text: "Copper Infrastructure", link: "#"},
            {text: "Fiber Optic Infrastructure", link: "#"},
            {text: "Network Ranks", link: "#"},
            {text: "Power Management", link: "#"},
            {text: "KVM Switch & Console", link: "#"},
            {text: "Tools & Testers", link: "#"},
          ]
        },
        {title: "Company", items: [{text: "About Us", link: "#"}, /* more items */]},
        {title: "Contact", items: [{text: "Contact Us", link: "#"}, /* more items */]}
      ],

      phoneIcon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"30\" height=\"30\" viewBox=\"0 0 30 30\" fill=\"none\"><path d=\"M25 13.7487H27.5C27.5 7.33625 22.6587 2.5 16.2375 2.5V5C21.315 5 25 8.67875 25 13.7487Z\" fill=\"#BBBBBB\"/><path d=\"M16.25 9.99981C18.8787 9.99981 20 11.1211 20 13.7498H22.5C22.5 9.71856 20.2812 7.49981 16.25 7.49981V9.99981ZM20.5275 16.8036C20.2873 16.5853 19.9717 16.4688 19.6472 16.4788C19.3228 16.4889 19.015 16.6246 18.7887 16.8573L15.7975 19.9336C15.0775 19.7961 13.63 19.3448 12.14 17.8586C10.65 16.3673 10.1987 14.9161 10.065 14.2011L13.1387 11.2086C13.3718 10.9825 13.5077 10.6746 13.5177 10.3501C13.5277 10.0256 13.4111 9.70986 13.1925 9.46981L8.57374 4.39106C8.35504 4.15026 8.05108 4.00419 7.72643 3.98389C7.40177 3.96358 7.08198 4.07063 6.83499 4.28231L4.12249 6.60856C3.90638 6.82545 3.77739 7.11412 3.75999 7.41981C3.74124 7.73231 3.38374 15.1348 9.12374 20.8773C14.1312 25.8836 20.4037 26.2498 22.1312 26.2498C22.3837 26.2498 22.5387 26.2423 22.58 26.2398C22.8856 26.2227 23.1741 26.0931 23.39 25.8761L25.715 23.1623C25.9268 22.9155 26.0341 22.5958 26.014 22.2711C25.9939 21.9465 25.8481 21.6424 25.6075 21.4236L20.5275 16.8036Z\" fill=\"#BBBBBB\"/></svg>",
      contactNumbers: ["+88 (02) 223374052", "+88 (02) 223374052"],
      emailIcon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"30\" height=\"30\" viewBox=\"0 0 30 30\" fill=\"none\"><path d=\"M27.5 5H2.5V25H27.5V5ZM25 10L15 16.25L5 10V7.5L15 13.75L25 7.5V10Z\" fill=\"#BBBBBB\"/></svg>",
      contactEmails: ["sales@artive.com.bd", "info@artive.com.bd"]
    };
  },
  methods: {
    joinNewsletter() {
      // Validate if email exists (replace with your actual validation logic if needed)
      if (this.email && this.email.trim() !== "") {
        // Create a form data object
        const formDataObject = new FormData();
        formDataObject.append("email", this.email);

        // Use fetch API to submit the form
        fetch('/news-letter/', {
          method: 'POST',
          body: formDataObject, // Send as form data
        })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Form submission failed');
              }
              return response.json();
            })
            .then((data) => {
              console.log('Form submission successful:', data);
              alert(`Thank you, ${this.email}, for joining the newsletter!`);
              this.email = ""; // Clear the email field
            })
            .catch((error) => {
              console.error('Error during form submission:', error);
              alert('There was an error submitting the form. Please try again later.');
            });
      } else {
        // Handle validation failure
        console.error('Form validation failed');
        alert('Please enter a valid email address.');
      }
    },
  },
}



</script>

<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
.all_reserved {
  padding: 12px;
  background: #1AE5FE;
  text-align: center;
}

.all_reserved h2 {
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.footer {
  background: linear-gradient(100deg, #1C666F 0%, #012E35 100%);
  width: 100%;
  padding: 200px 0px 0px;
  color: white;
  position: relative;
  top: 70px;
}

.main_part {
  padding: 0 80px 80px;
}

.footer_desc {
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.caption_head h2 {
  color: #F9FAFB;
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 33.6px */
  letter-spacing: -0.28px;
}

.caption_head p {
  color: #F9FAFB;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

ul {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 0;
}

ul li {
  list-style: none;
}

ul li a {
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.footer_items h2 {
  color: rgba(255, 255, 255, 0.80);
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 18px;
}

.footer_number p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.input-group {
  border-radius: 50px;
  border: 1px solid #FFF;
  width: fit-content;
  height: fit-content;
}

.input-group input {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0 20px;
  color: #ABABAB !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height:  14px;
  letter-spacing: 0.7px;
}

.input-group input::placeholder {
  color: #ABABAB;
  opacity: 1;
}

.input-group input::-ms-input-placeholder {
  color: #ABABAB;
}

.input-group input:focus {
  background: transparent;
  box-shadow: none;
  color: #ABABAB !important;
}

.input-group button {
  border-radius: 0px 50px 50px 0px;
  background: #FFF;
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.7px;
  padding: 20px;
}

.get_connect {
  height: 180px;
  width: 80%;
  position: absolute;
  top: -90px;
  left: 10%;
}

.carousel-item img {
  border-radius: 16px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
}

.carousel-caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px;
  color: white;
  position: absolute;
  top: 0px;
  left: 0%;
  height: 100%;
  width: 80%;
  text-align: left;
}

.phone_email {
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
}

@media only screen and (max-width: 375px) {
  .footer {
    padding: 100px 0px 0;
    margin-top: 120px;
  }

  ul li a {
    font-size: 14px;
  }

  .main_part {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    justify-content: center !important;
    width: 100%;
    padding: 0 20px 20px;
  }

  .first_part {
    width: 100% !important;
    align-items: center !important;
  }

  .second_part {
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 40px !important;
  }

  .call_area {
    width: 100%;
  }

  .footer_desc {
    text-align: center;
    font-size: 16px;
  }

  .carousel-caption {
    padding: 20px;
  }

  .caption_head h2 {
    color: #F9FAFB;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.18px;
  }

  .caption_head p {
    color: #F9FAFB;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }

  .carousel-caption {
    flex-direction: column;
  }

  .get_connect {
    height: 250px;
    top: -180px;
  }

  .caption_head {
    width: 100% !important;
  }

  .caption_input {
    width: 100% !important;
  }

  .input-group {
    width: 100%;
    font-size: 12px;

  }

  .input-group input {
    padding: 0 10px;
  }

  .input-group button {
    font-size: 12px;
  }

  .phone_email {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .footer {
    padding: 100px 0px 0;
    margin-top: 120px;
  }

  ul li a {
    font-size: 14px;
  }

  .main_part {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    justify-content: center !important;
    width: 100%;
    padding: 0 20px 20px;
  }

  .first_part {
    width: 100% !important;
    align-items: center !important;
  }

  .second_part {
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 40px !important;
  }

  .call_area {
    width: 100%;
  }

  .footer_desc {
    text-align: center;
    font-size: 16px;
  }

  .carousel-caption {
    padding: 20px;
  }

  .caption_head h2 {
    color: #F9FAFB;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.18px;
  }

  .caption_head p {
    color: #F9FAFB;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }

  .carousel-caption {
    flex-direction: column;
  }

  .get_connect {
    height: 250px;
    top: -180px;
  }

  .caption_head {
    width: 100% !important;
  }

  .caption_input {
    width: 100% !important;
  }

  .input-group {
    width: 100%;
    font-size: 12px;
  }

  .input-group input {
    padding: 0 10px;
  }

  .input-group button {
    font-size: 12px;
  }

  .phone_email {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
  }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .footer {
    padding: 100px 0px 0;
    margin-top: 120px;
  }

  ul li a {
    font-size: 14px;
  }

  .main_part {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    justify-content: center !important;
    width: 100%;
    padding: 0 20px 20px;
  }

  .first_part {
    width: 100% !important;
    align-items: center !important;
  }

  .second_part {
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 40px !important;
  }

  .call_area {
    width: 100%;
  }

  .footer_desc {
    text-align: center;
    font-size: 16px;
  }

  .carousel-caption {
    padding: 30px;
  }

  .caption_head h2 {
    color: #F9FAFB;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.18px;
  }

  .caption_head p {
    color: #F9FAFB;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }

  .carousel-caption {
    flex-direction: column;
  }

  .get_connect {
    height: 250px;
    top: -180px;
  }

  .caption_head {
    width: 100% !important;
  }

  .caption_input {
    width: 100% !important;
  }

  .input-group {
    width: 100%;
    font-size: 12px;
  }

  .input-group button {
    font-size: 12px;
  }

  .phone_email {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
  }

}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .footer {
    padding: 100px 0px 0;
    margin-top: 170px;
  }


  .main_part {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    justify-content: center !important;
    width: 100%;
    padding: 0 40px 40px;
  }


  .phone_email {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
  }


  .first_part {
    width: 100% !important;
    align-items: center !important;
  }

  .call_area {
    width: 100%;
  }

  .second_part {
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 40px !important;
  }

  .footer_desc {
    text-align: center;
    font-size: 16px;
  }

  .carousel-caption {
    padding: 60px;
  }

  .caption_head h2 {
    color: #F9FAFB;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.18px;
  }

  .caption_head p {
    color: #F9FAFB;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }

  .carousel-caption {
    flex-direction: column;
  }

  .get_connect {
    height: 300px;
    top: -230px;
  }

  .caption_head {
    width: 100% !important;
  }

  .caption_input {
    width: 100% !important;
  }

  .input-group {
    width: 100%;
  }

}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1440px) {
}
</style>