<template>
  <div id="scrollspyHeading1">
    <div class="elec">
      <div v-for="(section, index) in parsedSections" :key="index" class="description">
        <h3>{{ section.title }}</h3>
        <ul class="listing">
          <li v-for="(item, idx) in section.items" :key="idx">{{ item }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OperatingSystemDetails",
  props: {
    sections: {
      type: String, // Accepts a JSON string
      required: true,
    },
  },
  computed: {
    parsedSections() {
      try {
        return JSON.parse(this.sections);
      } catch (error) {
        console.error("Invalid JSON for sections:", error);
        return [];
      }
    },
  },
};
</script>


<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


.elec {
  padding: 80px;
}

.description h3 {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.listing li {
  color: #696969;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}


@media only screen and (max-width: 375px) {
  .elec {
    padding: 20px;
  }


  .description h3 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .listing li {
    color: #696969;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .elec {
    padding: 20px;
  }


  .description h3 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .listing li {
    color: #696969;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .elec {
    padding: 20px;
  }


  .description h3 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .listing li {
    color: #696969;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {

}

@media only screen and  (min-width: 992px) and (max-width: 1199px) {

}
</style>