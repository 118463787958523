<template>
  <div class="product-page">
    <nav aria-label="breadcrumb" class="d-none d-lg-block">
      <ol class="breadcrumb">
        <li v-for="(item, index) in breadcrumbs" :key="index" class="breadcrumb-item"
            :class="{ 'active': item.active }">
          <template v-if="index === 0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
              <path
                  d="M1 5.8125L8.875 1L16.75 5.8125M15 8.875V14.475C15 14.765 14.765 15 14.475 15H3.275C2.98505 15 2.75 14.765 2.75 14.475V8.875"
                  stroke="#0F1E2A" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </template>
          <a v-if="!item.active" :href="item.link">{{ item.text }}</a>
          <span v-else>{{ item.text }}</span>
        </li>
      </ol>
    </nav>

    <div class="row m-0 main_div">
      <div class="col-lg-6">
        <div class="tab-container">
          <div class="tabs">
            <div v-for="(image, index) in productImages" :key="index"
                 class="tab"
                 :class="{ active: activeTab === index }"
                 @click="setActiveTab(index)">
              <div><img :src="image" class="thumbnail-img mx-1" alt="Thumbnail" width="80"></div>
            </div>
          </div>
          <div class="tab-content-container">
            <div v-for="(image, index) in productImages" :key="index"
                 :id="'tab_' + index"
                 class="tab-content"
                 :class="{ active: activeTab === index }">
              <img :src="image" class="img-fluid product-image" alt="Main Product Image">
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6" style="padding: 20px">
        <h2 class="right_side_text">{{ productTitle }}</h2>
        <ul class="list-unstyled feature-list d-flex flex-column gap-4">
          <li v-for="(feature, index) in productFeatures" :key="index" class="d-flex align-items-start gap-2">
            <img style="width: fit-content; height: fit-content" src="./Product_Details_Image/Frame%201000006118.png"
                 alt="Feature icon">
            <div class="text_desc">{{ feature }}</div>
          </li>
        </ul>

        <div class="d-flex flex-column details align-items-start gap-3">
          <h3>SKU: <span>{{ sku }}</span></h3>
          <h3>Category: <span>{{ category }}</span></h3>
          <h3>Tag: <span>{{ tags }}</span></h3>
        </div>

        <div class="d-flex align-items-center gap-3 mt-3">
          <a href="#" class="share text-decoration-none d-flex align-items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
              <path
                  d="M1.50019 14.0041H10.5013V10.2536C10.5013 10.0547 10.5803 9.86387 10.721 9.7232C10.8617 9.58253 11.0525 9.5035 11.2514 9.5035C11.4503 9.5035 11.6411 9.58253 11.7818 9.7232C11.9225 9.86387 12.0015 10.0547 12.0015 10.2536V14.7542C12.0015 14.9531 11.9225 15.1439 11.7818 15.2845C11.6411 15.4252 11.4503 15.5042 11.2514 15.5042H0.750093C0.551156 15.5042 0.360367 15.4252 0.219697 15.2845C0.0790274 15.1439 0 14.9531 0 14.7542V10.2536C0 10.0547 0.0790274 9.86387 0.219697 9.7232C0.360367 9.58253 0.551156 9.5035 0.750093 9.5035C0.94903 9.5035 1.13982 9.58253 1.28049 9.7232C1.42116 9.86387 1.50019 10.0547 1.50019 10.2536V14.0041ZM5.25065 8.00332V11.0037C5.25065 11.2026 5.32968 11.3934 5.47035 11.5341C5.61102 11.6748 5.8018 11.7538 6.00074 11.7538C6.19968 11.7538 6.39047 11.6748 6.53114 11.5341C6.67181 11.3934 6.75083 11.2026 6.75083 11.0037V8.00332C6.75202 7.009 7.14754 6.05575 7.85063 5.35266C8.55372 4.64957 9.50698 4.25405 10.5013 4.25285H12.5138L11.5844 5.23848C11.448 5.3833 11.3746 5.57641 11.3805 5.77532C11.3864 5.97422 11.4711 6.16264 11.6159 6.29911C11.7608 6.43558 11.9539 6.50893 12.1528 6.50302C12.3517 6.49711 12.5401 6.41243 12.6766 6.2676L14.7828 4.03383L14.7978 4.01883C14.8226 3.98565 14.8447 3.95054 14.8638 3.91381C14.8954 3.87414 14.9226 3.83113 14.9448 3.78555C15.0184 3.60523 15.0184 3.40329 14.9448 3.22298C14.9225 3.17746 14.8953 3.13446 14.8638 3.09471C14.8447 3.05799 14.8226 3.02287 14.7978 2.9897L14.7828 2.9747L12.6766 0.737921C12.6093 0.664873 12.5283 0.605896 12.4381 0.564408C12.3479 0.522919 12.2504 0.499745 12.1512 0.496229C12.052 0.492712 11.9531 0.508924 11.8602 0.543923C11.7673 0.578922 11.6822 0.632013 11.61 0.700118C11.5378 0.768223 11.4798 0.849987 11.4394 0.94067C11.399 1.03135 11.377 1.12915 11.3747 1.2284C11.3724 1.32764 11.3898 1.42635 11.426 1.51882C11.4621 1.61128 11.5162 1.69566 11.5852 1.76705L12.5138 2.75267H10.5013C9.10929 2.75446 7.7748 3.30822 6.7905 4.29252C5.8062 5.27682 5.25243 6.61131 5.25065 8.00332Z"
                  fill="#1E1E1E"/>
            </svg>
            Share
          </a>
          <a v-for="(icon, index) in socialIcons" :key="index" :href="icon.link">
            <component :is="icon.component"/>
          </a>
        </div>
      </div>
    </div>

    <nav id="navbar-example2" class="navbar navi_gation bg-white px-3 w-100"
         style="box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);">
      <ul class="nav nav-pills nav_pill d-flex justify-content-between w-100" style="margin: 0 100px;">
        <template v-for="(item, index) in navItems" :key="index">
          <li class="nav-item">
            <a class="nav-link text-black interactive_items" :href="item.href">{{ item.text }}</a>
          </li>
          <!-- Add a VR bar only if it's not the last item -->
          <div v-if="index < navItems.length - 1" class="vr mx-3"></div>
        </template>
      </ul>
    </nav>


    <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-root-margin="0px 0px -40%"
         data-bs-smooth-scroll="true" class="scrollspy-example bg-body-tertiary position-relative rounded-2"
         tabindex="0">
      <div id="scrollspyHeading1">
        <div class="w-100 part_two_top">
          <img class="w-100 h-100" src="./Product_Details_Image/enjoy.png" alt="Enjoy Crystal Clear 4K Display">
          <div class="part_two_top_text">
            <h2>{{ enjoySection.title }}</h2>
            <p>{{ enjoySection.description }}</p>
          </div>
        </div>
        <input type="checkbox" id="click" v-model="showMoreContent">
        <div class="content " :class="{ 'show-content': showMoreContent }">
          <!-- Content for "View More" section -->
          <div class="w-100 part_two_bottom bg-white">
            <div class="d-flex flex-column-reverse flex-md-row align-items-center bg-white">
              <div class="w-50 part_two_bottom_head ">
                <img class="w-100" src="./Product_Details_Image/Rectangle%204244.png" alt="Bezel Aluminum Frame Gray">
              </div>
              <div class="w-50  part_two_bottom_text " style="padding-left: 80px;">
                <h2>{{ bezelSection.title }}</h2>
                <p>{{ bezelSection.description }}</p>
                <div class="">
                  <img src="./Product_Details_Image/Vector%20(3).svg" alt="Vector">
                </div>
              </div>
            </div>
            <div class="d-flex flex-column flex-md-row align-items-center bg-white">
              <div class="w-50 part_two_bottom_text " style="padding-right: 80px;">
                <h2>{{ bezelSection.title }}</h2>
                <p>{{ bezelSection.description }}</p>
                <div class="">
                  <img src="./Product_Details_Image/Vector%20(3).svg" alt="Vector">
                </div>
              </div>
              <div class="w-50 part_two_bottom_text_two">
                <img class="w-100" src="./Product_Details_Image/Rectangle%204244.png" alt="Bezel Aluminum Frame Gray">
              </div>

            </div>
          </div>
          <div>
            <div class="w-100 part_one_top">
              <div class="part_one_top_text">
                <h1>{{ seamlessly.title }}</h1>
                <p>{{ seamlessly.description }}</p>
              </div>
              <img class="w-100" height="700" src="./Product_Details_Image/enjoy.png"
                   alt="">
            </div>
            <div class="w-100 part_one_bottom">
              <div class="w-50 one_bottom_left">
                <img class="w-100" src="./Product_Details_Image/Rectangle%204244.png" alt="">
                <h3>CPU (A73 2.28GHz)</h3>
              </div>
              <div class="w-50 one_bottom_right">
                <img class="w-100" src="./Product_Details_Image/Rectangle%204245.png" alt="">
                <h3> 8 GB RAM,128 GB ROM</h3>
              </div>
            </div>
          </div>
          <div class="specification" id="scrollspyHeading2">
            <div class="table-container">
              <table class="spec-table">
                <thead>
                <tr>
                  <th v-for="header in headers" :key="header">{{ header }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in specifications" :key="index" :class="{ 'even-row': index % 2 === 1 }">
                  <template v-if="row.rowSpan">
                    <td :rowspan="row.rowSpan">{{ row.key }}</td>
                    <td>{{ row.values[0] }}</td>
                  </template>
                  <template v-else-if="row.subRow">
                    <td>{{ row.values[0] }}</td>
                  </template>
                  <template v-else>
                    <td>{{ row.key }}</td>
                    <td>{{ row.values[0] }}</td>
                  </template>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="download-section" id="scrollspyHeading3">
            <div v-for="(item, index) in downloadItems" :key="index" class="download-item">
              <p class="title">{{ item.title }}</p>
              <a :href="item.link" class="download-link">
                {{ item.text }}
                <span class="download-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_668_780)">
                      <path
                          d="M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17"
                          stroke="#0081B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7 11L12 16L17 11" stroke="#0081B4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M12 4V16" stroke="#0081B4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_668_780">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="for_btn" v-if="!showMoreContent">
          <label for="click" @click="toggleContent" class="view_more">View More</label>
        </div>
      </div>
    </div>

    <div class="related-product">
      <h3>Related Product</h3>
      <Swiper
          :modules="[Navigation]"
          :slides-per-view="3"
          :space-between="25"
          :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
          :breakpoints="{
          320:{
          slidesPerView: 1,
          spaceBetween: 20,
            },
          640: {
          slidesPerView: 2,
          spaceBetween: 20,
           },
          768: {
          slidesPerView: 3,
          spaceBetween: 25,
          },
          1024: {
          slidesPerView: 4,
          spaceBetween: 30,
           }
          }"

          class="mySwiper"
      >
        <SwiperSlide v-for="product in relatedProducts" :key="product.id">
          <div class="card position-relative">
            <span v-if="product.isNew" class="Badge">New</span>
            <img :src="product.image" class="card-img-top" :alt="product.name">
            <div class="card-body">
              <p class="card-text">{{ product.name }}</p>
            </div>
          </div>
        </SwiperSlide>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {Swiper, SwiperSlide} from 'swiper/vue'
import {Navigation} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'

// Breadcrumbs data
const breadcrumbs = ref([
  {text: 'Home', link: '#', active: false},
  {text: 'Products', link: '#', active: false},
  {text: 'KVM Switch & Rack Console', link: '#', active: false},
  {text: 'Short Depth Full HD 18.5" Widescreen LCD TP KVM over IP', link: '#', active: true}
])

// Product data
const productTitle = ref('Short Depth Full HD 18.5" " Widescreen LCD TP KVM over IP (with USB VGA Dongles)')
const productImages = ref([
  'http://new.artive.com.bd/media/images/Rectangle_1034.png',
  'http://new.artive.com.bd/media/images/Rectangle_1034.png',
  'http://new.artive.com.bd/media/images/Rectangle_1034.png'
])
const productFeatures = ref([
  'Support VGA, DVI, HDMI, DP Port servers Connectivity',
  'Supports Windows, Linux, Unix, Mac, Solaris (Sun), Redhat, Ubuntu',
  'Support Keyboard and mouse emulation to ensure the normal booting',
  'Support Auto scanning for monitoring user-selected computer',
  'Hot pluggable–add or remove computers without having to power down the switch',
  'Support port sharing mode for allowing local and remote users to gain access to a server simultaneously',
  'Video synchronization with local console – EDID information for local console display can be stored in the host module for video resolution optimization.'
])
const sku = ref('N/A')
const category = ref('KVM Switch & Rack Console')
const tags = ref('Kvm, tp, vga, dvi, port')

// Social media icons (you'll need to import or create these components)
const FacebookIcon = {
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13 0C20.1797 0 26 5.82031 26 13C26 19.4887 21.2461 24.8669 15.0312 25.8421L15.0312 16.758H18.0604V16.7578L18.6367 13.0002L18.6367 13H15.0313V10.5614C15.0313 10.4329 15.0391 10.3048 15.0561 10.179C15.063 10.1278 15.0714 10.0769 15.0813 10.0266C15.2429 9.21185 15.8191 8.53141 17.1498 8.53141H18.7891L18.7891 8.53127L18.7891 5.33218L18.7891 5.33204C18.7891 5.33204 17.3014 5.07814 15.879 5.07814C13.0951 5.07814 11.2154 6.65989 10.9913 9.54224C10.9763 9.7344 10.9688 9.93233 10.9688 10.136C10.9688 10.136 10.9687 10.1361 10.9687 10.1361L10.9688 13H7.66798V13.0002H7.66797V16.758H10.9687L10.9688 25.8421C4.75391 24.8669 0 19.4887 0 13C0 5.82031 5.82029 0 13 0Z" fill="url(#paint0_linear_668_859)" fill-opacity="0.6"/>
      <defs>
        <linearGradient id="paint0_linear_668_859" x1="13.0122" y1="4.41661e-07" x2="13.0122" y2="25.8421" gradientUnits="userSpaceOnUse">
          <stop stop-color="#313131"/>
          <stop offset="1" stop-color="#1E1E1E"/>
        </linearGradient>
      </defs>
    </svg>
  `
}

const YoutubeIcon = {
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path d="M11.2684 15.5035L15.5238 13.0051L11.2684 10.5066V15.5035Z" fill="url(#paint0_linear_668_863)" fill-opacity="0.6"/>
      <path d="M13 0C5.81953 0 0 5.81953 0 13C0 20.1805 5.81953 26 13 26C20.1805 26 26 20.1805 26 13C26 5.81953 20.1805 0 13 0ZM19.5 15.3258C19.5 17.5652 17.2605 17.5652 17.2605 17.5652H8.73945C6.5 17.5652 6.5 15.3258 6.5 15.3258V10.6793C6.5 8.43984 8.73945 8.43985 8.73945 8.43985H17.2605C19.5 8.43985 19.5 10.6793 19.5 10.6793V15.3258Z" fill="url(#paint1_linear_668_863)" fill-opacity="0.6"/>
      <defs>
        <linearGradient id="paint0_linear_668_863" x1="13.0122" y1="4.44359e-07" x2="13.0122" y2="26" gradientUnits="userSpaceOnUse">
          <stop stop-color="#313131"/>
          <stop offset="1" stop-color="#1E1E1E"/>
        </linearGradient>
        <linearGradient id="paint1_linear_668_863" x1="13.0122" y1="4.44359e-07" x2="13.0122" y2="26" gradientUnits="userSpaceOnUse">
          <stop stop-color="#313131"/>
          <stop offset="1" stop-color="#1E1E1E"/>
        </linearGradient>
      </defs>
    </svg>
  `
}

const LinkedinIcon = {
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path d="M13 0C5.81953 0 0 5.81953 0 13C0 20.1805 5.81953 26 13 26C20.1805 26 26 20.1805 26 13C26 5.81953 20.1805 0 13 0ZM9.36406 18.4387H6.83008V10.3289H9.36406V18.4387ZM8.02852 9.31328H8.0082C7.08906 9.31328 6.49492 8.69375 6.49492 7.90664C6.49492 7.1043 7.10937 6.5 8.04375 6.5C8.97812 6.5 9.55195 7.1043 9.57227 7.90664C9.57734 8.68867 8.9832 9.31328 8.02852 9.31328ZM19.5 18.4387H16.6258V14.2441C16.6258 13.1473 16.1789 12.3957 15.1887 12.3957C14.432 12.3957 14.0105 12.9035 13.8176 13.391C13.7465 13.5637 13.7566 13.8074 13.7566 14.0562V18.4387H10.9078C10.9078 18.4387 10.9434 11.0043 10.9078 10.3289H13.7566V11.6035C13.9242 11.0449 14.8332 10.2527 16.2855 10.2527C18.0883 10.2527 19.5 11.4207 19.5 13.9293V18.4387Z" fill="url(#paint0_linear_668_865)" fill-opacity="0.6"/>
      <defs>
        <linearGradient id="paint0_linear_668_865" x1="13.0122" y1="4.44359e-07" x2="13.0122" y2="26" gradientUnits="userSpaceOnUse">
          <stop stop-color="#313131"/>
          <stop offset="1" stop-color="#1E1E1E"/>
        </linearGradient>
      </defs>
    </svg>
  `
}

const WhatsappIcon = {
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9962 0C5.82956 0 0 5.83119 0 12.9999C0 15.8429 0.916848 18.4795 2.47542 20.6196L0.855611 25.4496L5.85241 23.8526C7.90758 25.213 10.3601 26 13.0038 26C20.1704 26 26 20.1686 26 13.0001C26 5.83142 20.1704 0.000214895 13.0038 0.000214895L12.9962 0ZM9.36696 6.60336C9.11489 5.99965 8.92384 5.97679 8.54196 5.96126C8.41194 5.95371 8.26703 5.94617 8.10639 5.94617C7.60958 5.94617 7.09013 6.09133 6.77682 6.41227C6.39494 6.80203 5.44745 7.71137 5.44745 9.57623C5.44745 11.4411 6.80744 13.2447 6.99072 13.4971C7.18177 13.749 9.64209 17.6314 13.4622 19.2137C16.4495 20.4518 17.336 20.337 18.0159 20.1919C19.0091 19.9779 20.2546 19.2439 20.5679 18.3577C20.8812 17.4709 20.8812 16.7143 20.7893 16.5538C20.6977 16.3934 20.4454 16.3019 20.0635 16.1106C19.6816 15.9195 17.8248 15.0024 17.4733 14.8801C17.1294 14.7502 16.801 14.7962 16.5414 15.163C16.1746 15.6751 15.8156 16.1949 15.5251 16.5081C15.2959 16.7527 14.9214 16.7833 14.6083 16.6533C14.188 16.4777 13.0115 16.0646 11.5597 14.7731C10.4365 13.7721 9.67252 12.5264 9.45107 12.152C9.2294 11.77 9.42818 11.5481 9.60371 11.3419C9.79475 11.1048 9.97806 10.9368 10.1691 10.7151C10.3602 10.4936 10.4671 10.3788 10.5894 10.1189C10.7194 9.86677 10.6275 9.60686 10.5359 9.41576C10.4442 9.22466 9.68027 7.35979 9.36696 6.60336Z" fill="url(#paint0_linear_668_866)" fill-opacity="0.6"/>
      <defs>
        <linearGradient id="paint0_linear_668_866" x1="13.0122" y1="4.44359e-07" x2="13.0122" y2="26" gradientUnits="userSpaceOnUse">
          <stop stop-color="#313131"/>
          <stop offset="1" stop-color="#1E1E1E"/>
        </linearGradient>
      </defs>
    </svg>
  `
}
const CopyIcon = {
  template: `
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <circle cx="13" cy="13" r="13" fill="white"/>
        <path d="M16.9999 13.9V18.1C16.9999 21.6 15.5999 23 12.0999 23H7.89994C4.39994 23 2.99994 21.6 2.99994 18.1V13.9C2.99994 10.4 4.39994 9 7.89994 9H12.0999C15.5999 9 16.9999 10.4 16.9999 13.9Z" fill="white"/>
        <path d="M22.9999 7.9V12.1C22.9999 15.6 21.5999 17 18.0999 17H16.9999V13.9C16.9999 10.4 15.5999 9 12.0999 9L8.99994 9V7.9C8.99994 4.4 10.3999 3 13.8999 3H18.0999C21.5999 3 22.9999 4.4 22.9999 7.9Z" fill="white"/>
        <path d="M16.9999 13.9V18.1C16.9999 21.6 15.5999 23 12.0999 23H7.89994C4.39994 23 2.99994 21.6 2.99994 18.1V13.9C2.99994 10.4 4.39994 9 7.89994 9H12.0999M16.9999 13.9C16.9999 10.4 15.5999 9 12.0999 9M16.9999 13.9V17H18.0999C21.5999 17 22.9999 15.6 22.9999 12.1V7.9C22.9999 4.4 21.5999 3 18.0999 3H13.8999C10.3999 3 8.99994 4.4 8.99994 7.9V9L12.0999 9" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
  `
}

const socialIcons = ref([
  {link: '#', component: FacebookIcon},
  {link: '#', component: YoutubeIcon},
  {link: '#', component: LinkedinIcon},
  {link: '#', component: WhatsappIcon},
  {link: '#', component: CopyIcon}
])

// Navigation items
const navItems = ref([
  {href: '#scrollspyHeading1', text: 'Operating System'},
  {href: '#scrollspyHeading2', text: 'Specifications'},
  {href: '#scrollspyHeading3', text: 'Download'}
])

// Content sections
const enjoySection = ref({
  title: 'Enjoy Crystal Clear 4K Display',
  description: 'Our interactive flat panels boast 4K display technology, ensuring everything you showcase appears in stunning clarity. From presentations to videos, every detail shines brightly, making your meetings and lessons more engaging than ever.'
})

const bezelSection = ref({
  title: 'Bezel Aluminum Frame Gray',
  description: 'The bezel refers to the frame around the screen. This interactive flat panel features a sleek aluminum bezel in a modern gray finish. The sturdy aluminum construction provides durability and a premium look, while the neutral gray color complements any setting, making it an attractive and stylish addition to your office, classroom, or conference room.'
})

const seamlessly = ref({
  title: 'Seamlessly Simple All-in-One Interactive Flat Panel',
  description: 'Our interactive flat panel has both Windows, Android 11, Mac, Linux and Chrome OSall one line givingall one line  you the best of both worlds. With a fast 2.2 GHz CPU, 8 GB RAM, and 128 GB storage,all one line itall one line handles everything smoothly. Switch between Windows for work tasks and Android forall one line appsall one line easily. Perfect for meetings, teaching, and presentations.'
})

// Specifications
const headers = ref(['Model', 'TKhsysg'])
const specifications = ref([
  {key: 'Integrated 8 or 16 Ports', values: ['Push button, UI interface, Web']},
  {key: 'Part Emulation', values: ['LCD Model']},
  {key: 'Viewing Area', values: ['18.5"']},
  {key: 'Optimum Resolution', values: ['1920x1080@60Hz']},
  {key: 'LCD Monitor', values: ['Display Color: 16.7 M'], rowSpan: 4},
  {subRow: true, values: ['Brightness: 350cd/m² (Typ)']},
  {subRow: true, values: ['Contrast Ratio: 1000:1 (Typ)']},
  {subRow: true, values: ['Pixel pitch(mm): 0.213(H) x 0.213 (V)']},
  {key: 'Mouse', values: ['X/Y Resolution: >1000 points / inch, (40 points/mm)']},
  {key: 'Trackball Function', values: ['Supports']},
  {key: 'Keyboard', values: ['10/100/1000Mbps RJ-45 Copper']},
  {key: 'Ethernet Interface', values: ['3840*2160 /60hz']},
  {key: 'Remote Control', values: ['KVM Control (ActiveX Control)']},
  {key: 'Remote Management', values: ['WEB browser-based']},
  {key: 'RS485 Serial Interface', values: ['RJ-45 Copper']},
  {key: 'Security', values: ['Supports DES, AES128, AES256 Encryptions']},
  {key: 'Optimum Resolution', values: ['1920x1080@60Hz'], rowSpan: 4},
  {subRow: true, values: ['Input Power: 100V - 240Vac, 50 ~ 60Hz, <1.5A']},
  {subRow: true, values: ['Power Consumption: 35W']},
  {subRow: true, values: ['Operating Temperature: 0 ~ 50 ℃']},
  {key: 'Storage Temperature', values: ['-20 ~ 60 ℃']},
])

// Download items
const downloadItems = ref([
  {
    title: 'Datasheet',
    text: 'SHORT DEPTH FULL HD 18.5" WIDESCREEN LCD TP KVM OVER IP (WITH USB VGA DONGLES)',
    link: '#'
  },
  {
    title: 'User\'s Manual',
    text: 'SHORT DEPTH FULL HD 18.5" WIDESCREEN LCD TP KVM OVER IP (WITH USB VGA DONGLES)',
    link: '#'
  }
])

// Related products
const relatedProducts = ref([
  {
    id: 1,
    name: '2-Post Heavy Duty Open Frame Rack',
    image: 'http://new.artive.com.bd/media/images/product_card.png',
    isNew: true
  },
  {
    id: 2,
    name: 'Tempered Glass Door Network Rack',
    image: 'http://new.artive.com.bd/media/images/product_card.png',
    isNew: true
  },
  {
    id: 3,
    name: 'Wall Mount Network Rack',
    image: 'http://new.artive.com.bd/media/images/product_card.png',
    isNew: true
  },
  {
    id: 4,
    name: 'Wall Mount Network Rack',
    image: 'http://new.artive.com.bd/media/images/product_card.png',
    isNew: true
  },
  {
    id: 5,
    name: 'Wall Mount Network Rack',
    image: 'http://new.artive.com.bd/media/images/product_card.png',
    isNew: true
  },
  {
    id: 6,
    name: 'Wall Mount Network Rack',
    image: 'http://new.artive.com.bd/media/images/product_card.png',
    isNew: true
  },
  // Add more products as needed
])

// Active tab state
const activeTab = ref(0)

// Show more content state
const showMoreContent = ref(false)

const toggleContent = () => {
  showMoreContent.value = !showMoreContent.value
}

// Set active tab
const setActiveTab = (index) => {
  activeTab.value = index
}

// Initialize Swiper
onMounted(() => {
  new Swiper('.mySwiper', {
    modules: [Navigation],
    slidesPerView: 4,
    spaceBetween: 25,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })
})
</script>

<style scoped>

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}





/* Include all the CSS from the original HTML here */
.product-image {
  max-width: 100%;
}

.thumbnail-img {
  max-width: 100%;
  cursor: pointer;
}

.feature-list i {
  color: #333;
  margin-right: 8px;
}

.tab-container {
  display: flex;
  border-radius: 4px;
  max-width: 800px;
  margin: 20px auto;
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.tabs .tab {
  padding: 10px;
  cursor: pointer;
  background-color: #ffffff;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
}

.tabs .tab:hover,
.tabs .tab.active {
  background-color: #f1f1f1;
}

.tab-content-container {
  width: 70%;
  border-radius: 8px;
  background: rgba(201, 203, 208, 0.25);
  margin-left: 20px;
}

.tab-content {
  padding: 20px;
  display: none;
  opacity: 0;
  transition: opacity 1s ease;
}

.tab-content.active {
  display: block;
  opacity: 1;
}

.tab img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  vertical-align: middle;
}

.tab div {
  display: inline-block;
  vertical-align: middle;
}

.breadcrumb {
  background: #F6F6F6;
  padding: 20px 80px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '';
  background-image: url("./Product_Details_Image/Vector (3).svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  width: 12px;
  height: 12px;
}

.right_side_text {
  color: #222;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.share {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.main_div {
  padding: 0 80px;
}

.details h3 {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.5px;
}

.details span {
  color: #282828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.5px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #1E1E1E !important;
  background: transparent !important;
}

.part_one_top {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 100px;
}

.part_one_top_text {
  max-width: 800px;
  margin: auto;
}

.part_one_top_text h1 {
  color: #1E1E1E;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

.part_one_top_text p {
  color: #1E1E1E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.part_one_bottom {
  display: flex;
  gap: 80px;
  padding: 80px;
  align-items: center;
  justify-content: space-between;
  background: #F7F7F7;
}

.one_bottom_left,
.one_bottom_right {
  text-align: center;
}

.one_bottom_left img,
.one_bottom_right img {
  border-radius: 10px;
  margin-bottom: 35px;
}

.one_bottom_left h3,
.one_bottom_right h3 {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 128%;
}

.part_two_top {
  height: 900px;
  position: relative;
}

.part_two_top_text {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 700px;
}

.part_two_top_text h2 {
  color: #1E1E1E;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

.part_two_top_text p {
  color: #1E1E1E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.part_two_bottom {
  padding: 60px;
  margin-bottom: 100px;
}

.part_two_bottom_text {
  padding-left: 80px;
}

.part_two_bottom_text h2 {
  color: #1E1E1E;
  font-size: 28px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.part_two_bottom_text p {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
}

.business-img {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #1D1C1C 0%, rgba(30, 30, 30, 0.50) 23.49%, rgba(30, 30, 30, 0.00) 52.38%);
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 20px;
}

.gradient-2 {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #ff0000 4.5%, rgba(52, 134, 5, 0.5) 33.49%, rgba(30, 30, 30, 0.00) 52.38%);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.Artiv {
  margin-top: 80px;
}

.carousel-caption {
  position: absolute;
  right: 5%;
  bottom: 5.25rem;
  left: 5%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: start;
}

.business_card_text {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.5px;
}

.nav_pills {
  display: flex;
  width: fit-content;
  margin: auto;
  border-radius: 55px;
  padding: 5px;
  border: 1px solid #DADAD9 !important;
}

.two-img {
  height: 650px;
}

.nav_link {
  border-radius: 55px !important;
  transition: 0.5s;
  color: #070707;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}

.nav_pills {
  margin-bottom: 40px;
}

.nav-pills .nav_link.active,
.nav-pills .show > .nav_link {
  color: var(--bs-nav-pills-link-active-color);
  background: #1E1E1E !important;
}

.caption {
  position: static;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  margin-right: 40px !important;
  width: 15px !important;
  height: 15px !important;
}

.card_body {
  padding: 40px;
}

.card_body h5 {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.card_body p {
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
}

.related-product {
  padding: 80px;
}

.related-product h3 {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.5px;
  margin-bottom: 40px;
}

.card-text {
  color: #1E1E1E;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.Badge {
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 4px;
  background: #FFAB0A;
  padding: 4px 8px;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 20px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: #1E1E1E;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.swiper-button-next {
  right: 0px !important;
}

.swiper-button-prev {
  left: 0px !important;
}

.specification {
  padding: 80px;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.spec-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.spec-table th,
.spec-table td {
  padding: 15px;
  text-align: left;
}

.spec-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  width: 30%;
}

.spec-table td {
  background-color: #ffffff;
}

.spec-table tr:nth-child(even) th,
.spec-table tr:nth-child(even) td {
  background-color: #f9f9f9;
}

.download-section {
  padding: 80px;
}

.download-item {
  margin-bottom: 20px;
}

.download-item .title {
  font-weight: bold;
  margin-bottom: 5px;
}

.download-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #0081B4;
}

.download-icon {
  margin-left: 10px;
}

.for_btn {
  width: 100%;
  text-align: center;
  background: linear-gradient(0deg, #FFF 28.5%, rgba(255, 255, 255, 0.00) 99.5%);
  position: absolute;
  bottom: 0px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.for_btn label {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 250% */
  letter-spacing: 0.5px;
  border-radius: 30px;
  border: 1px solid #FDFBFB;
  background: #EFEDED;
  padding: 10px 24px;

}

.view_more {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0081B4;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.view_more:hover {
  background-color: #006a93;
}

#click {
  display: none;
}

.content {
  display: none;
}

#click:checked ~ .content {
  display: block;
}

@media only screen and (max-width: 375px) {
  .vr {
    margin: 0 !important;
  }

  .navi_gation ul {
    margin: 0 !important;
    flex-wrap: nowrap !important;
    overflow: scroll;
  }

  .navi_gation ul li a {
    text-wrap: nowrap;
  }

  .part_one_bottom {
    gap: 20px;
    padding: 20px;
  }

  .part_two_top_text {
    width: 100%;
  }

  .part_two_top_text {
    width: 100%;
    padding: 20px;
  }

  .one_bottom_left h3, .one_bottom_right h3 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .part_one_top_text {
    /* max-width: 800px; */
    padding: 20px;
  }

  .part_two_bottom {
    padding: 20px;
    margin-bottom: 100px;
  }

  .part_one_top_text h1 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-bottom: 24px;
  }

  .part_one_top_text p {
    color: #696969;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  .part_two_bottom_head {
    width: 100% !important;
  }

  .part_two_bottom_text_two {
    width: 100% !important;
  }

  .part_two_bottom_text {
    width: 100% !important;
    padding: 0 !important;
    margin: 20px 0;
  }

  .part_two_top_text h2 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .part_two_top_text p {
    color: #696969;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .interactive_items {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.5px;
  }

  .text_desc {
    color: #2B2B2B;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
  }

  .right_side_text {
    color: #222;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.5px;
  }

  .tab-container {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 4px;
    width: 100%;
    gap: 20px;
  }

  .tab-content-container {
    width: 100%;
    margin-left: 0;
  }

  .tab-content {
    text-align: center;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    justify-content: center;
  }

  .main_div {
    padding: 0 20px;
  }

  .part_two_bottom_text {
    padding-left: 0px;
  }

  .part_two_bottom_text h2 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */;
    margin-bottom: 20px;
  }

  .part_two_bottom_text p {
    color: #696969;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom: 20px;
  }

  .specification {
    padding: 40px;
  }

  .download-section {
    padding: 40px;
  }

  .related-product {
    padding: 40px;
  }

}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .vr {
    margin: 0 !important;
  }

  .navi_gation ul {
    margin: 0 !important;
    flex-wrap: nowrap !important;
    overflow: scroll;
  }

  .navi_gation ul li a {
    text-wrap: nowrap;
  }

  .part_one_bottom {
    gap: 20px;
    padding: 20px;
  }

  .part_two_top_text {
    width: 100%;
  }

  .part_two_top_text {
    width: 100%;
    padding: 20px;
  }

  .one_bottom_left h3, .one_bottom_right h3 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .part_one_top_text {
    /* max-width: 800px; */
    padding: 20px;
  }

  .part_two_bottom[data-v-1c354f03] {
    padding: 20px;
    margin-bottom: 100px;
  }

  .part_one_top_text h1 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-bottom: 24px;
  }

  .part_one_top_text p {
    color: #696969;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  .part_two_bottom_head {
    width: 100% !important;
  }

  .part_two_bottom_text_two {
    width: 100% !important;
  }

  .part_two_bottom_text {
    width: 100% !important;
    padding: 0 !important;
    margin: 20px 0;
  }

  .part_two_top_text h2 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-bottom: 24px;
  }

  .part_two_top_text p {
    color: #696969;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  .interactive_items {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.5px;
  }

  .text_desc {
    color: #2B2B2B;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
  }

  .part_two_bottom_text h2 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */;
    margin-bottom: 20px;
  }

  .part_two_bottom_text p {
    color: #696969;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom: 20px;
  }

  .right_side_text {
    color: #222;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.5px;
  }

  .tab-container {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 4px;
    width: 100%;
    gap: 20px;
  }

  .tab-content-container {
    width: 100%;
    margin-left: 0;
  }

  .tab-content {
    text-align: center;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    justify-content: center;
  }

  .main_div {
    padding: 0 20px;
  }

  .part_two_bottom_text {
    padding-left: 0px;
  }

  .specification {
    padding: 40px;
  }

  .download-section {
    padding: 40px;
  }

  .related-product {
    padding: 40px;
  }

}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .vr {
    margin: 0 !important;
  }

  .navi_gation ul {
    margin: 0 !important;
    flex-wrap: nowrap !important;
    overflow: scroll;
  }

  .navi_gation ul li a {
    text-wrap: nowrap;
  }

  .part_two_bottom_text h2 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */;
    margin-bottom: 20px;
  }

  .part_two_bottom_text p {
    color: #696969;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom: 20px;
  }

  .part_one_bottom {
    gap: 20px;
    padding: 20px;
  }

  .part_two_top_text {
    width: 100%;
  }

  .part_two_top_text {
    width: 100%;
    padding: 20px;
  }

  .one_bottom_left h3, .one_bottom_right h3 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .part_one_top_text {
    /* max-width: 800px; */
    padding: 20px;
  }

  .part_two_bottom[data-v-1c354f03] {
    padding: 20px;
    margin-bottom: 100px;
  }

  .part_one_top_text h1 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-bottom: 24px;
  }

  .part_one_top_text p {
    color: #696969;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  .part_two_bottom_head {
    width: 100% !important;
  }

  .part_two_bottom_text_two {
    width: 100% !important;
  }

  .part_two_bottom_text {
    width: 100% !important;
    padding: 0 !important;
    margin: 20px 0;
  }

  .part_two_top_text h2 {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-bottom: 24px;
  }

  .part_two_top_text p {
    color: #696969;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  .interactive_items {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.5px;
  }

  .text_desc {
    color: #2B2B2B;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
  }

  .right_side_text {
    color: #222;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.5px;
  }

  .tab-container {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 4px;
    width: 100%;
    gap: 20px;
  }

  .tab-content-container {
    width: 100%;
    margin-left: 0;
  }

  .tab-content {
    text-align: center;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    justify-content: center;
  }

  .main_div {
    padding: 0 20px;
  }

  .part_two_bottom_text {
    padding-left: 0px;
  }

  .specification {
    padding: 40px;
  }

  .download-section {
    padding: 40px;
  }

  .related-product {
    padding: 40px;
  }

}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .tab-container {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 4px;
    width: 100%;
    gap: 20px;
  }

  .tab-content-container {
    width: 100%;
    margin-left: 0;
  }

  .tab-content {
    text-align: center;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    justify-content: center;
  }

  .main_div {
    padding: 0 40px;
  }

  .part_two_bottom_text {
    padding-left: 0px;
  }

  .specification {
    padding: 40px;
  }

  .download-section {
    padding: 40px;
  }

  .related-product {
    padding: 40px;
  }
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1440px) {

}

</style>