<template>
  <div class="category_card my-4">
    <div class="row g-4 g-xl-5">
      <div
          v-for="(item, index) in items"
          :key="index"
          :class="[
          'col-md-6',
          index === items.length - 1 ? 'col-12 col-md-12' : 'col-6'
        ]"
      >
        <div class="card text-center card_area">
          <div class="card-body d-flex">
            <div class="img_area">
              <img
                  :src="item.image"
                  class="card-img-top mx-auto mb-3"
                  :alt="item.imageAlt"
                  style="max-width: 200px;"
              />
            </div>
            <div class="text_area">
              <h5 class="card-title">{{ item.title }}</h5>
              <p class="card-text">{{ item.description }}</p>
              <button class="view_btn d-none d-lg-inline-block " @click.prevent="navigateToUrl(item.url)">View All</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    urlOne: {
      type: String,
      default: '',
    },
    urlTwo: {
      type: String,
      default: '',
    },
    urlThree: {
      type: String,
      default: '',
    },
    urlFour: {
      type: String,
      default: '',
    },
    urlFive: {
      type: String,
      default: '',
    },
    urlSix: {
      type: String,
      default: '',
    },
    urlSeven: {
      type: String,
      default: '',
    },
  },
  computed: {
    items() {
      return [
        {
          title: "Copper Infrastructure",
          description: "Strong & Reliable",
          image: "https://safenet.bitquintet.com/media/images/copper_200x200.webp",
          imageAlt: "Copper Infrastructure",
          url: this.urlOne,
        },
        {
          title: "Fiber Optic Infrastructure",
          description: "High-Speed Connections",
          image: "https://safenet.bitquintet.com/media/images/fiber_200x200.webp",
          imageAlt: "Fiber Optic Infrastructure",
          url: this.urlTwo,
        },
        {
          title: "Network & Server Racks",
          description: "Secure & Organized",
          image: "https://safenet.bitquintet.com/media/images/Network_200x200.webp",
          imageAlt: "Network & Server Racks",
          url: this.urlThree,
        },
        {
          title: "KVM Switch & Rack Console",
          description: "Streamlining Server Control",
          image: "https://safenet.bitquintet.com/media/images/KVM__200x200.webp",
          imageAlt: "KVM Switch & Rack Console",
          url: this.urlFour,
        },
        {
          title: "Electrical Power Management",
          description: "Maximizing Performance",
          image: "https://safenet.bitquintet.com/media/images/Electrical_power__200x200.webp",
          imageAlt: "Power Management",
          url: this.urlFive,
        },
        {
          title: "LAN Tools & Testers",
          description: "Ensuring Network Reliability",
          image: "https://safenet.bitquintet.com/media/images/LAN_200x200.webp",
          imageAlt: "LAN Tools & Testers",
          url: this.urlSix,
        },
        {
          title: "Raised Floor System",
          description: "Ensuring Network Reliability",
          image: "https://safenet.bitquintet.com/media/images/Raised_200x200.webp",
          imageAlt: "LAN Tools & Testers",
          url: this.urlSeven,
        },
      ];
    },
  },
  methods: {
    navigateToUrl(url) {
      // Navigate to the given URL
      window.location.href = url;
    },

  },
  created() {

  }
};
</script>

<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.card {
  border-radius: 8px;
}

.card_area {
  background: #F6F6F6;

}

.card-body {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 60px;
  height: 300px;
}

.category_card {
  padding: 80px;
}

.img_area {
  height: 100%;
}

.img_area img {
  height: fit-content;
}


.text_area {
  width: 45%;
}

.img_area {
  width: 55%;
}

.text_area {
  text-align: start;
}

.text_area h5 {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 171.429% */
  letter-spacing: 0.8px;
  text-transform: capitalize;
}

.card {
  border: none;
}

.text_area p {
  color: #222;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 100% */
  margin: 0;
}

.view_btn {
  margin-top: 30px;
  color: #222;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  padding: 13px 30px;
  border-radius: 40px;
  border: 2px solid #222;
}

@media only screen and (max-width: 375px) {
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 250px;
  }


  .text_area {
    width: 100%;
    height: 40%;
  }

  .img_area {
    width: 100%;
    height: 60%;
  }

  .text_area {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .category_card {
    padding: 30px 20px;
  }

  .text_area h5 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .text_area p {
    color: #525151;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    margin: 0;
  }


}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 250px;
  }


  .text_area {
    width: 100%;
    height: 40%;
  }

  .img_area {
    width: 100%;
    height: 60%;
  }

  .text_area {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .category_card {
    padding: 30px 20px;
  }

  .text_area h5 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .text_area p {
    color: #525151;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    margin: 0;
  }

}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 300px;
  }


  .text_area {
    width: 100%;
    height: 40%;
  }

  .img_area {
    width: 100%;
    height: 60%;
  }

  .text_area {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .category_card {
    padding: 30px 20px;
  }

  .text_area h5 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .text_area p {
    color: #525151;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    margin: 0;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 300px;
  }


  .text_area {
    width: 100%;
    height: 40%;
  }

  .img_area {
    width: 100%;
    height: 60%;
  }

  .text_area {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .category_card {
    padding: 30px 30px;
  }

  .text_area h5 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .text_area p {
    color: #525151;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    margin: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .category_card {
    padding: 30px 40px;
  }

  .card-body {
    padding: 30px;
  }

  .text_area {
    width: 45%;
  }

  .text_area h5 {
    font-size: 22px;
    line-height: 40px;
  }

  .img_area {
    width: 55%;
    height: fit-content;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1400px) {
  .category_card {
    padding: 30px 40px;
  }

  .card-body {
    padding: 30px;
  }

  .text_area h5 {
    font-size: 22px;
    line-height: 40px;
  }

  .text_area {
    width: 45%;
  }

  .img_area {
    width: 55%;
    height: fit-content;
  }
}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1440px) {

}
</style>
