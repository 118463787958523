import {createApp} from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import {BootstrapVueNext} from 'bootstrap-vue-next';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import "swiper/css";

import { VueReCaptcha } from 'vue-recaptcha-v3';


import HelloWorld from './components/HelloWorld.vue'
import SafeNav from "@/components/SafeNav";
import SafeFooter from "@/components/SafeFooter";
import HeroArea from "@/components/HeroArea";
import CategoryCard from '@/components/CategoryCard'
import NetworkingSolution from '@/components/NetworkingSolution'
import ProductSlider from '@/components/ProductSlider'
import ProductHighlight from '@/components/ProductHighlight'
import ServerManagement from '@/components/ServerManagement'
import HighNetwork from '@/components/HighNetworkcable'
import BrandPromotion from '@/components/BrandPromotion'
import AllProduct from '@/components/AllProduct'
import AboutUs from '@/components/AboutUs'
import ContactUs from '@/components/ContactUs'
import ProductDetails from '@/components/ProductDetails'
import TestArea from '@/components/TestArea'
import MegaMenu from '@/components/MegaMenu'
import AllProducts from "@/components/AllProducts";
import BreadCrumb from "@/components/Breadcrumb";
import ProductMain from "@/components/ProductMain";
import ProductNav from "@/components/ProductNav";
import DownLoad from "@/components/DownLoad";
import ProductDetailTwo from "@/components/ProductDetailTwo";
import OperatingSystemDetails from "@/components/OperatingSystemDetails";
import ProductSpecifications2 from "@/components/ProductSpecifications2";


const app = createApp({})

app.use(VueReCaptcha, {
  siteKey: '6Lfo0HEqAAAAAGHTx6raTlSVvW4bBWvP8ybkaTXf', // Replace with your actual site key from Google 6Lfo0HEqAAAAAG_5K6XcsrCH92AyCQ1cFol_3Rku
  loaderOptions: {
    autoHideBadge: true,
  },
});

app.component('hello-world', HelloWorld)
app.component('safe-nav', SafeNav)
app.component('safe-footer', SafeFooter)
app.component('hero-area', HeroArea)
app.component('category-card', CategoryCard)
app.component('networking-solution', NetworkingSolution)
app.component('product-slider', ProductSlider)
app.component('product-highlight', ProductHighlight)
app.component('server-management', ServerManagement)
app.component('high-network', HighNetwork )
app.component('brand-promotion', BrandPromotion)
app.component('all-product', AllProduct)
app.component('about-us', AboutUs)
app.component('contact-us', ContactUs)
app.component('product-details', ProductDetails)
app.component('test-area', TestArea)
app.component('mega-menu', MegaMenu)
app.component('all-products', AllProducts)
app.component('bread-crumb', BreadCrumb)
app.component('product-main', ProductMain)
app.component('product-specifications', ProductSpecifications2)
app.component('product-nav', ProductNav)
app.component('down-load', DownLoad)
app.component('product-detailtwo', ProductDetailTwo)
app.component('operating-system-details', OperatingSystemDetails)

// Make BootstrapVue available throughout your project
app.use(BootstrapVueNext);
// Optionally install the BootstrapVue icon components plugin

app.mount('body')