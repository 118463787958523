<template>
  <div style="background: #F8FAFD">
    <!-- Swiper Section 1 -->
    <div class="d-flex section_one">
      <div class="pw pwl">
        <h2 class="highlight-title">{{ firstTitle }}</h2>
        <p>{{ firstTag }}</p>
        <a class="d-none d-lg-inline-block show_more cursor-pointer" :href="firstUrl">
          Show more
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M1 11L11 1M11 1V11M11 1H1" stroke="#1E1E1E" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </a>


      </div>
      <swiper
          class="mySwiper"
          :slides-per-view="3"
          :space-between="50"
          :modules="modules"
          :navigation="{ nextEl: '.swiper-button-next-1', prevEl: '.swiper-button-prev-1' }"
          :breakpoints="breakpoints"
      >
        <swiper-slide v-for="(product, index) in filteredProducts(firstTag)"
          :key="index">
          <div class="card position-relative">
            <span class="badge bg-success Badge" v-if="product.status">{{ product.status }}</span>
            <img :src="product.image" class="card-img-top" alt="Product Image"/>
            <div class="card-body">
              <p class="card-text">{{ product.title }}</p>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-next swiper-button-next-1"></div>
        <div class="swiper-button-prev swiper-button-prev-1"></div>
      </swiper>
    </div>

    <!-- Swiper Section 2 -->
    <div class="d-flex section_two">
      <swiper
          class="mySwiper"
          :slides-per-view="3"
          :space-between="50"
          :modules="modules"
          :navigation="{ nextEl: '.swiper-button-next-2', prevEl: '.swiper-button-prev-2' }"
          :breakpoints="breakpoints"
      >
        <swiper-slide v-for="(product, index) in filteredProducts(secondTag)"
          :key="index">
          <div class="card position-relative">
            <span class="badge bg-success Badge" v-if="product.status">{{ product.status }}</span>
            <img :src="product.image" class="card-img-top" alt="Product Image"/>
            <div class="card-body">
              <p class="card-text">{{ product.title }}</p>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-next swiper-button-next-2"></div>
        <div class="swiper-button-prev swiper-button-prev-2"></div>
      </swiper>
      <div class="pw pwr ms-auto ms-lg-0 text-end text-lg-start">
        <h2 class="highlight-title">{{ secondTitle }}</h2>
        <p>{{ secondTag }}</p>
        <a class="d-none d-lg-inline-block show_more cursor-pointer" :href="secondUrl">
          Show more
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M1 11L11 1M11 1V11M11 1H1" stroke="#1E1E1E" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </a>

      </div>
    </div>
  </div>
</template>

<script>
import {ref, computed } from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

export default {
  name: "ProductHighlight",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    products: {
      type: String,
      required: true
    },
    firsttitle: {
      type: String,
      required: true
    },
    secondtitle: {
      type: String,
      required: true
    },
    firsttag: {
      type: String,
      required: true
    },
    secondtag: {
      type: String,
      required: true
    },
    firsturl: {
      type: String,
      required: true
    },
    secondurl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    console.log(props.firsttitle);
    console.log(props.firsturl); // Should log correctly
    console.log(props.secondurl);
    const productsData = ref(JSON.parse(props.products));
    const firstTitle = computed(() => props.firsttitle);
    const secondTitle = computed(() => props.secondtitle);
    const firstTag = computed(() => props.firsttag);
    const secondTag = computed(() => props.secondtag);
    const firstUrl = computed(() => props.firsturl);
    const secondUrl = computed(() => props.secondurl);

    const modules = [Navigation];

    // Set breakpoints for responsive design
    const breakpoints = {
      // when window width is >= 320px (mobile)
      320: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
      // when window width is >= 640px (tablet)
      426: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
      // when window width is >= 1024px (desktop)
      1500: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    };
    const filteredProducts = (tag) => {
      return productsData.value.filter((product) => product.tags === tag);
    };

    return {
      productsData,
      firstTitle,
      secondTitle,
      firstTag,
      secondTag,
      firstUrl,
      secondUrl,
      modules,
      breakpoints,
      filteredProducts
    };
  },
  methods: {
    navigateToUrl(url) {
      // Navigate to the given URL
      window.location.href = url;
    },

  },
  created() {
  }
};
</script>

<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html,
body {
  position: relative;
  height: fit-content;
}

body {
  background: #eee;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}


.swiper {
  height: fit-content;
  padding: 80px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: #D9D9D9;
}

.card {
  border: none;
  width: 100%;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
}

.card-text {
  color: #222;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
  letter-spacing: 0.1px;
}

.Badge {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #fff;
  border-radius: 4px;
  background: #00da74;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
}

.pw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  max-width: 340px;
  min-width: 340px;
}




.pw h2 {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  letter-spacing: 0.8px;
  text-transform: capitalize;
}

.pw p {
  color: #222;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
}

.show_more {
  color: #1E1E1E;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  padding: 13px 24px;
  text-decoration: none;
  border-radius: 40px;
  border: 2px solid #000000;
  display: inline-block;
  width: fit-content;
}

/* Responsive styling for phone screens */
/*@media (max-width: 640px) {*/
/*  .swiper {*/
/*    padding: 40px;*/
/*  }*/

/*  .pw {*/
/*    padding: 40px;*/
/*  }*/

/*  .pw h2 {*/
/*    font-size: 28px;*/
/*  }*/

/*  .pw p {*/
/*    font-size: 14px;*/
/*    line-height: 14px;*/
/*  }*/
/*}*/


@media only screen and (max-width: 375px) {
  .section_one {
    flex-direction: column;
  }

  .section_two {
    flex-direction: column-reverse;
  }

  .swiper {
    width: 100%;
    padding: 20px;
  }

  .pw {
    padding: 20px;
  }


  .pw h2 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .pw p {
    color: #222;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
  }

  .card-text {
    color:  #1E1E1E;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .section_one {
    flex-direction: column;
  }

  .section_two {
    flex-direction: column-reverse;
  }

  .swiper {
    width: 100%;
    padding: 20px;
  }

  .pw {
    padding: 20px;
  }

  .pw h2 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .pw p {
    color: #222;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
  }
   .card-text {
    color:  #1E1E1E;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .section_one {
    flex-direction: column;
  }

  .section_two {
    flex-direction: column-reverse;
  }

  .swiper {
    width: 100%;
    padding: 20px;
  }

  .pw {
    padding: 20px;
  }

  .pw h2 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .pw p {
    color: #222;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
  }
   .card-text {
    color:  #1E1E1E;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    text-transform: capitalize;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .section_one {
    flex-direction: column;
  }

  .section_two {
    flex-direction: column-reverse;
  }

  .swiper {
    width: 100%;
    padding: 40px;
  }

  .pw {
    padding: 40px;
  }

  .pw h2 {
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .pw p {
    color: #222;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
  }
   .card-text {
    color:  #1E1E1E;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    text-transform: capitalize;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .pw h2 {
    font-size: 30px;
    line-height: 45px;
  }

  .pw {
    padding: 40px;
  }

  .swiper {
    width: 100%;
    padding: 40px;
  }
}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1440px) {

}
</style>
