<template>
  <div class="download" :id="this.id">
    <div class="d-flex flex-column down" style="gap: 40px;">
      <div class="d-flex flex-column text-down" style="gap: 20px;">
        <h3 class="mb-0">Datasheet</h3>
        <a :href="firstUrl" class="d-flex align-items-center " target="_blank" style="gap: 10px;">
          <h4>{{ firstTitle }}</h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_334_371)">
              <path
                  d="M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17"
                  stroke="#0081B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 11L12 16L17 11" stroke="#0081B4" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M12 4V16" stroke="#0081B4" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_334_371">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
      <div class="d-flex flex-column text-down" style="gap: 20px;">
        <h3 class="mb-0">User's Manual</h3>
        <a :href="secondUrl" class="d-flex align-items-center "  target="_blank" style="gap: 10px;">
          <h4>{{ secondTitle }}</h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_334_371)">
              <path
                  d="M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17"
                  stroke="#0081B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 11L12 16L17 11" stroke="#0081B4" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M12 4V16" stroke="#0081B4" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_334_371">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownLoadTwo",
  props: {
    firstTitle: {
      type: String,
      default: 'Artive Datasheet'
    },
    secondTitle: {
      type: String,
      default: 'Artive Manual'
    },
    firstUrl: {
      type: String,
      default: './Artives-Interactive-Details-image/Group 1000001830.png'
    },
    secondUrl: {
      type: String,
      default: './Artives-Interactive-Details-image/Group 1000001830.png'
    },
    id: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>


a {
  text-decoration: none;
}

.download {
  padding: 80px;
  background: #FAFAFA;
}

.download h3 {
  color: #1E1E1E;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-transform: capitalize;
}

.download h4 {
  color: #0081B4;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 100% */
  font-variant: all-small-caps;
  margin-bottom: 0;
}

@media only screen and (max-width: 576px) {
  .download {
    padding: 20px;
    background: #FAFAFA;
  }

  .download h3 {
    color: #1E1E1E;
    font-size: 20px;
  }

  .download h4 {
    color: #0081B4;
    font-size: 14px;
  }

  .down {
    gap: 20px !important;
  }

  .text-down {
    gap: 12px !important;
  }
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .download {
    padding: 40px;
    background: #FAFAFA;
  }
}

</style>