<template>
  <div class="search-container">
    <div class="input-wrapper">
      <input
          type="text"
          v-model="searchQuery"
          placeholder="Search here.."
          class="search-input"
      />
      <button v-if="searchQuery" @click="clearSearch" class="clear-button">×</button>
    </div>
    <ul class="item-list">
      <li v-for="item in filteredItems" :key="item" class="item">
        <span>+</span> {{ item }}
      </li>
    </ul>


    <div class="pagination-container">
      <div class="items-per-page">
        <select id="itemsPerPage">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
        <span>Items per page</span>
      </div>
      <div class="pagination" id="pagination"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AllProducts",
  props: ['products', 'categories'],
  data() {
    return {
      categoriesData: JSON.parse(this.categories),
      productsData: JSON.parse(this.products),
      selectedCategory: null,
      selectedSubcategory: null,
      currentPage: 1,
      itemsPerPage: 12,
    };
  },
  computed: {
    filteredProducts() {
      let products = this.productsData;

      // Filter by category
      if (this.selectedCategory) {
        products = products.filter(product => product.subcategory.category.id === this.selectedCategory);
      }

      // Further filter by subcategory if selected
      if (this.selectedSubcategory) {
        products = products.filter(product => product.subcategory.id === this.selectedSubcategory);
      }

      return products;
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredProducts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredProducts.length / this.itemsPerPage);
    }
  },
  methods: {
    getFilteredProducts(categoryId) {
      return this.productsData.filter(product => product.categoryId === categoryId);
    },
    countProducts(categoryId) {
      // Count products under a specific category by checking nested category in subcategory
      return this.productsData.filter(product => product.subcategory.category.id === categoryId).length;
    },
    selectCategory(categoryId, subcategoryId = null) {
      // Filter by category and optionally by subcategory
      this.selectedCategory = categoryId;
      this.selectedSubcategory = subcategoryId;
      this.currentPage = 1;
    },
    countSubcategoryProducts(subcategoryId) {
      return this.productsData.filter(product => product.subcategoryId === subcategoryId).length;
    },
    selectSubcategory(subcategoryId) {
      this.selectedSubcategory = subcategoryId;
      this.currentPage = 1;
    },
    clearFilters() {
      this.selectedCategory = null;
      this.selectedSubcategory = null;
      this.currentPage = 1;
      this.updateURL();
    },
    setItemsPerPage(count) {
      this.itemsPerPage = parseInt(count);
      this.currentPage = 1;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    updateURL() {
      const params = new URLSearchParams();
      if (this.selectedCategory) params.set("category", this.selectedCategory);
      if (this.selectedSubcategory) params.set("subcategory", this.selectedSubcategory);
      params.set("page", this.currentPage.toString());
      params.set("itemsPerPage", this.itemsPerPage.toString());

      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState(null, null, newUrl);
    },
    applyFiltersFromURL() {
      const params = new URLSearchParams(window.location.search);
      const category = params.get("category");
      const subcategory = params.get("subcategory");
      const page = params.get("page");
      const itemsPerPage = params.get("itemsPerPage");

      if (category) this.selectedCategory = parseInt(category);
      if (subcategory) this.selectedSubcategory = parseInt(subcategory);
      if (page) this.currentPage = parseInt(page);
      if (itemsPerPage) this.itemsPerPage = parseInt(itemsPerPage);
    },
    isCategoryOpen(categoryId) {
      return this.selectedCategory === categoryId;
    },
    updatePagination() {
      this.updateURL();
      // Here you would typically fetch new data based on the current page and items per page
      console.log(`Fetching data for page ${this.currentPage} with ${this.itemsPerPage} items per page`);
    }
  },
  created() {
    this.applyFiltersFromURL();
  },
  watch: {
    currentPage() {
      this.updatePagination();
    },
    itemsPerPage() {
      this.updatePagination();
    }
  }
};
</script>

<!--<script>-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      searchQuery: '',-->
<!--      items: ['Interactive Flat panel', 'Digital Signage', 'Mini Projector'],-->
<!--    };-->
<!--  },-->
<!--  computed: {-->
<!--    filteredItems() {-->
<!--      return this.items.filter(item =>-->
<!--        item.toLowerCase().includes(this.searchQuery.toLowerCase())-->
<!--      );-->
<!--    },-->
<!--  },-->
<!--  methods: {-->
<!--    clearSearch() {-->
<!--      this.searchQuery = '';-->
<!--    },-->
<!--  },-->
<!--};-->
<!--</script>-->



<style scoped>
.search-container {
  width: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
}

.input-wrapper {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 8px;
  padding-right: 24px; /* Space for the clear button */
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
}

.clear-button {
  position: absolute;
  top: 40%;
  right: 8px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 16px;
  color: #888;
  cursor: pointer;
}

.clear-button:hover {
  color: #555;
}

.item-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px 0;
  cursor: pointer;
}

.item span {
  margin-right: 8px;
  color: #888;
}
</style>
