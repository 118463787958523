<template>
  <div class="row m-0 main_div">
    <div class="col-md-6">
      <div class="tab-container">
        <div class="tabs">
          <div
              v-for="(tab, index) in parsedData.tabs"
              :key="index"
              class="tab"
              :class="{ active: activeTab === index }"
              @click="setActiveTab(index)"
          >
            <img :src="tab.thumbnail" class="thumbnail-img mx-1" :alt="`Thumbnail ${index + 1}`"/>
          </div>
        </div>
        <div class="tab-content-container">
          <transition name="fade" mode="out-in">
            <div v-if="currentTabContent" class="tab-content active">
              <img
                  :src="currentTabContent.image"
                  class="img-fluid product-image"
                  :alt="`Main Product Image ${activeTab + 1}`"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>

    <div class="col-md-6" style="padding: 20px">
      <h2 class="right_side_text">{{ parsedData.title }}</h2>
      <ul class="list-unstyled feature-list d-flex flex-column gap-4">
        <li
            v-for="(feature, index) in parsedData.features"
            :key="index"
            class="d-flex align-items-start gap-2"
        >
          <img style="width: fit-content; height: fit-content;" src="./Product_Details_Image/Frame%201000006118.png">
          <div>{{ feature.text }}</div>
        </li>
        <li class="d-flex flex-column details align-items-start gap-3">
          <h3>SKU: <span>{{ parsedData.sku }}</span></h3>
          <h3>Category: <span>{{ parsedData.category }}</span></h3>
          <h3>Tag: <span>{{ parsedData.tags }}</span></h3>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0, // Index of the currently active tab

    };
  },

  computed: {
    parsedData() {
      try {
        return JSON.parse(this.data);
      } catch (error) {
        console.error("Invalid JSON:", error);
        return {};
      }
    },
    currentTabContent() {
      // Return the currently active tab's content
      return this.parsedData.tabs[this.activeTab] || null;
    },
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index;
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
.product-image {
  max-width: 100%;
}

.thumbnail-img {
  max-width: 100%;
  cursor: pointer;
}

.feature-list i {
  color: #333;
  margin-right: 8px;
}


.tab-container {
  display: flex;
  border-radius: 4px;
  max-width: 800px;
  margin: 20px auto;
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.tabs .tab {
  padding: 10px;
  cursor: pointer;
  background-color: #ffffff;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
}

.tabs .tab:hover,
.tabs .tab.active {
  background-color: #f1f1f1;
}

.tab-content-container {
  width: 70%;
  border-radius: 8px;
  background: rgba(201, 203, 208, 0.25);
  margin-left: 20px;
}

.tab-content {
  padding: 20px;
  display: none;
  opacity: 0;
  transition: opacity 1s ease;
}

.tab-content.active {
  display: block;
  opacity: 1;
}

.tab img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  vertical-align: middle;
}

.tab div {
  display: inline-block;
  vertical-align: middle;
}

.breadcrumb {
  background: #F6F6F6;
  padding: 20px 80px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 85.714% */
  letter-spacing: 0.5px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 12px;
}


.right_side_text {
  color: #222;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; /* 135.714% */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.share {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.main_div {
  padding: 0 80px;
}

.details h3 {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  letter-spacing: 0.5px;
}

.details span {
  color: #282828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  letter-spacing: 0.5px;
}


.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #1E1E1E;
  background: transparent !important;
}


.part_one_top {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 100px;
}

.part_one_top_text {
  max-width: 800px;
  margin: auto;
}

.part_one_top_text h1 {
  color: #1E1E1E;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 76.8px */
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

.part_one_top_text p {
  color: #1E1E1E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
}

.part_one_bottom {
  display: flex;
  gap: 80px;
  padding: 80px;
  align-items: center;
  justify-content: space-between;
  background: #F7F7F7;
}

.one_bottom_left,
.one_bottom_right {
  text-align: center;

}

.one_bottom_left img,
.one_bottom_right img {
  border-radius: 10px;
  margin-bottom: 35px;
}

.one_bottom_left h3,
.one_bottom_right h3 {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 128%;
  /* 30.72px */
}

.part_two_top {
  height: 900px;
  position: relative;
}

.part_two_top_text {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 700px;
}

.part_two_top_text h2 {
  color: #1E1E1E;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 76.8px */
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

.part_two_top_text p {
  color: #1E1E1E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
}

.part_two_bottom {
  padding: 60px;
  margin-bottom: 100px;
}

.part_two_bottom_text {
  padding-left: 80px;
}

.part_two_bottom_text h2 {
  color: #1E1E1E;
  font-size: 28px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  /* 44.8px */
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.part_two_bottom_text p {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}


/* ----------------------- */

.business-img {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #1D1C1C 0%, rgba(30, 30, 30, 0.50) 23.49%, rgba(30, 30, 30, 0.00) 52.38%);
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 20px;
}

.gradient-2 {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #ff0000 4.5%, rgba(52, 134, 5, 0.5) 33.49%, rgba(30, 30, 30, 0.00) 52.38%);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.Artiv {
  margin-top: 80px;
}

.carousel-caption {
  position: absolute;
  right: 5%;
  bottom: 5.25rem;
  left: 5%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: start;
}

.business_card_text {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.5px;
}

.nav_pills {
  display: flex;
  width: fit-content;
  margin: auto;
  border-radius: 55px;
  padding: 5px;
  border: 1px solid #DADAD9 !important;
}

.two-img {
  height: 650px;
}

.nav_link {
  border-radius: 55px !important;
  transition: 0.5s;
  color: #070707;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 144.444% */
  text-transform: capitalize;
}

.nav_pills {
  margin-bottom: 40px;
}

.nav-pills .nav_link.active,
.nav-pills .show > .nav_link {

  color: var(--bs-nav-pills-link-active-color);
  background: #1E1E1E !important;
}

.caption {
  position: static;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

/* ---------------------------- */


.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  margin-right: 40px !important;
  width: 15px !important;
  height: 15px !important;
}

.card_body {
  padding: 40px;
}

.card_body h5 {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.card_body p {
  color: #1E1E1E;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.5px;
}

.part_three_bottom h1 {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 128%;
  /* 40.96px */
  text-align: center;
  margin-bottom: 80px;
}

.card_area {
  background: white;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
}

.card_text h3 {
  color: #1E1E1E;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 32px */
  letter-spacing: 0.5px;
  text-transform: capitalize;
  text-align: center;
}

.navi_gation {
  position: sticky;
  top: 0;
  z-index: 1;
}

#click {
  display: none;
}

.content {
  display: none;
  transition: 01s linear;
}

.for_btn {
  width: 100%;
  text-align: center;
  background: linear-gradient(0deg, #FFF 28.5%, rgba(255, 255, 255, 0.00) 99.5%);
  position: absolute;
  bottom: 0px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.for_btn label {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 250% */
  letter-spacing: 0.5px;
  border-radius: 30px;
  border: 1px solid #FDFBFB;
  background: #EFEDED;
  padding: 10px 24px;

}

#click:checked ~ .content {
  display: block;
}

#click:checked ~ .for_btn {
  display: none !important;
}

.swiper {
  height: fit-content;

}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*width: 370px !important;*/
  margin-right: 25px !important;
  background: rgba(227, 224, 224, 0.85);
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 30px;
  left: auto;
}

.card-body {
  padding: 20px;
}

.card-text {
  color: #1E1E1E;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  text-transform: capitalize;
}

.card img {
  height: 300px;
}

.card {
  border: none;
  background: transparent;
}

.Badge {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #FFF;
  border-radius: 4px;
  background: #FFAB0A;
  padding: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
}

.related-product h3 {
  color: #1E1E1E;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  letter-spacing: 0.8px;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.related-product {
  padding: 80px;
}


.table-container {
  margin: 20px 80px;
}

.spec-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.spec-table th, .spec-table td {
  border: 1px solid #e0e0e0;
  padding: 12px 15px;
  text-align: left;
  vertical-align: top;
}

.spec-table th {
  background-color: #f9f9f9;
  font-weight: bold;
  font-size: 16px;
}

.spec-table td {
  font-size: 14px;
}

.spec-table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.download-section {
  background-color: #ffffff;
  width: 100%;
  padding: 80px;
}

.download-item {
  margin-bottom: 20px;

}

.title {
  color: #1E1E1E;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-transform: capitalize;
  margin-bottom: 5px;
}

.download-link {
  color: #0081B4;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 100% */
  font-variant: all-small-caps;
  text-decoration: none;
}

.download-link:hover {
  text-decoration: underline;
}

.download-icon {
  margin-left: 8px;
  font-size: 18px;
  color: #0073e6;
}


@media only screen and (max-width: 375px) {
  .main_div {
    padding: 0 20px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  .tab-container {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .tab-content-container {
    width: 100%;
    border-radius: 8px;
    background: rgba(201, 203, 208, 0.25);
    margin-left: 0px;
  }

  .right_side_text {
    color: #222;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .feature-list li {
    color: #2B2B2B;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .main_div {
    padding: 0 20px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  .tab-container {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .tab-content-container {
    width: 100%;
    border-radius: 8px;
    background: rgba(201, 203, 208, 0.25);
    margin-left: 0px;
  }

  .right_side_text {
    color: #222;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .feature-list li {
    color: #2B2B2B;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .main_div {
    padding: 0 20px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  .tab-container {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .tab-content-container {
    width: 100%;
    border-radius: 8px;
    background: rgba(201, 203, 208, 0.25);
    margin-left: 0px;
  }

  .right_side_text {
    color: #222;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .feature-list li {
    color: #2B2B2B;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
  }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
  .main_div {
    padding: 0 40px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  .tab-container {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .tab-content-container {
    width: 100%;
    border-radius: 8px;
    background: rgba(201, 203, 208, 0.25);
    margin-left: 0px;
  }

  .right_side_text {
    color: #222;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .feature-list li {
    color: #2B2B2B;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
  }
}

@media only screen and  (min-width: 992px) and (max-width: 1199px) {
  .main_div {
    padding: 0 40px;
  }

}
</style>